import React from 'react'
import Navbar from '../components/navbar/Navbar'
import { JehonaBuilding } from '../components'
import { Box, Button, IconButton, Typography } from '@mui/material'
import FilterBox from '../components/filter/jehona/FilterBox'
import { useDispatch, useSelector } from 'react-redux'
import { getFilterMobileModalState, handleMobileModal, handleResetFilter } from '../features/filter/FilterSlice'
import MobileFilter from '../components/filter/jehona/MobileFilter'
import { Phone } from '@mui/icons-material'
import { colors, customFontFamily } from '../utils/theme'

const Jehona = () => {
  const filterModal = useSelector(getFilterMobileModalState)
  const dispatch = useDispatch()
  return (
    <div style={{ overflow: "hidden", position: 'relative', height: '100%'}}>
      <Navbar />
      <div style={{height: 80}}></div>
      <Box sx={{
        position: 'absolute',
        top: '10%',
        left: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }}>
        <Box sx={{ 
          width: {
            md: '95%',
            xl: '90%'
          },
          mt: {
            md: 3,
            xl: 1
          },
           display: {
            xs: 'none',
            md: 'block'
           }
      }}>
            <FilterBox />
        </Box>
      </Box>
      <Box
        sx={{
          overflow: {
            xs: 'auto',
            md: 'hidden'
          },
        }}
      >
        <Box sx={{
          display: {
            xs: 'flex',
            md: 'none'
          },
          position: 'fixed',
          bottom: 1,
          left: 0,
          justifyContent: 'space-between',
          width: '100%',

        }}>
          <Button variant='outlined' fullWidth sx={{
            m: 1,
            color: 'black',
            borderRadius: '50px',
            backgroundColor: ' white',
            borderColor: colors.lightGreen,
            ':hover': {
              borderColor: colors.lightGreen,
              backgroundColor: ' white',
            }
          }} onClick={() => {
            dispatch(handleResetFilter())
            
          }}>
            Reseto
          </Button>
          <Button onClick={() => {
            dispatch(handleMobileModal(true))
          }} fullWidth sx={{
            m: 1,
            borderRadius: '50px',
            backgroundColor: colors.lightGreen,
            ':hover': {
              backgroundColor: colors.lightGreen,
            },
            color: 'white'
          }} >
            Filtro
          </Button>
        </Box>
        <JehonaBuilding />
        {filterModal && <MobileFilter />}
      </Box>
    </div>
  );
}

export default Jehona