import { Box, Popover, Typography } from '@mui/material'
import React from 'react'
import { colors, generalPopUp } from '../../utils/theme';

const GenericPopUp = ( { anchorEl, item } ) => {
    const open = Boolean(anchorEl)
    console.log(item)
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      slotProps={{
        paper: {
          style: {
            backgroundColor: colors.lightGreen
          }
        }
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        pointerEvents: "none",
        "& .MuiPopover-paper": {
          borderRadius: "10px",
        },
        height: 350,
        overflow: 'visible'
      }}
    >
      
      <Box sx={{ ...generalPopUp.container }}>
        <Box display={'flex'} justifyContent={'space-between'} flexDirection={'column'} height={200}>
          <Box display={'flex'}>
          <Typography sx={{color: 'white'}} variant='h1'>{item?.floor}</Typography>
          <Typography sx={{color: 'white', mt: 2}} variant='h6'>KATI</Typography>
          </Box>
          <Box display={'flex'} gap={1}>
            <Typography sx={{color: 'white'}} fontWeight={700} variant='h5'>{item?.count}</Typography>
            <Typography sx={{color: 'white'}} variant='h5'>{('Të lira').toUpperCase()}</Typography>
          </Box>
        </Box>
      </Box>
    </Popover>
  );
}

export default GenericPopUp