import { Box, Divider, IconButton, InputAdornment, Slider, TextField, Typography } from '@mui/material'
import React from 'react'
import { calculatorStyles } from './styles'
import { AccountCircle, Close, Money } from '@mui/icons-material'
import { EURO_SYMBOL } from '../../utils/consts'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { handleCalculatorModalState } from '../../features/calculator/CalculatorSlice'


const Calculator = () => {
    const [totalPrice, setTotalPrice] = useState(0)
    const dispatch = useDispatch()
    const monthsLeft = 100;
    const getMonthlyPrice = () => {
        return ((totalPrice - (totalPrice * (30 / 100))) / monthsLeft).toFixed(2) + EURO_SYMBOL
    }
    const getToPayPrice = () => {
        return (totalPrice - (totalPrice - (totalPrice * (30 / 100)))).toFixed(2) + EURO_SYMBOL;
    }
  return (
    <Box sx={{...calculatorStyles.container}}>
        <Box sx={{...calculatorStyles.headerSection}}>
           <Box sx={{...calculatorStyles.closeContainer, borderRadius: '0px 25px 0px 25px'}}>
              <IconButton
              onClick={() => {
                dispatch(handleCalculatorModalState(false))
              }}
              sx={{color: 'white'}}>
              <Close fontSize='large' />
              </IconButton>
           </Box>
        </Box>
        <Box sx={{...calculatorStyles.mainSection, borderRadius: '25px'}}>
            <Typography>Shuma e banesës</Typography>
            <Box sx={{...calculatorStyles.mainInnerSection}}>
                <Box sx={{...calculatorStyles.priceBox}}>
                    <TextField 
                        variant='standard'
                        placeholder='Shëno shumën'
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: <InputAdornment position='end'>
                            {EURO_SYMBOL}</InputAdornment>
                            }}
                        inputProps={{
                            style: {
                                textAlign: 'center',
                            },
                        }}
                        name='totalPrice'
                        value={totalPrice}
                        onChange={(e) => setTotalPrice(e.currentTarget.value)}
                    />
                </Box>
                <Slider
                min={49000}
                max={400000}
                value={totalPrice}
                onChange={(e) => setTotalPrice(parseInt((e.target.value)).toFixed(2))}
                sx={{width: '100%', color: '#65984d'}} />
                <Typography>Kësti mujor / {monthsLeft} muaj</Typography>
                <Box sx={{...calculatorStyles.priceBox}}>
                    <Typography>{getMonthlyPrice()}</Typography>
                </Box>
                <Typography>Shuma e banesës</Typography>
                <Box sx={{...calculatorStyles.priceBox}}>
                    <Typography>{getToPayPrice()}</Typography>
                </Box>
            <Typography textAlign={'center'}>For a specific project</Typography>
           </Box>
        </Box>
    </Box>
  )
}

export default Calculator