import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import {
  Grid,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getJehonaApartmentStatus, resetStatusAndMsgJehona } from "../../features/objects/ObjectsSlice";
import { getApartmentEditModal, setApartmentEditModalState } from "../../features/apartment/ApartmentEditSlice";
import { updateApartment } from "../../features/objects/ObjectsApi";
import { handleSalesModalState } from "../../features/apartment/sales/SalesSlice";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 40,
  p: 2,
};

export default function SalesModal( { buildId } ) {
  const [open, setOpen] = React.useState(false);
  const apartmentEditData = useSelector(getApartmentEditModal);
  const dispatch = useDispatch();
  const msg =''
  const status = useSelector(getJehonaApartmentStatus)
  const [apartmentData, setApartmentData] = React.useState({
    id: "",
    rooms: 1,
    isSold: false,
    floor: 0,
    square: 0,
    name: "",
    imageData: null,
    apartmentNumber: 1,
    style: "",
    className: "",
    path: "",
    apartmentId: "",
    balconySquare: 0,
    imgUrl: ''
  });
  React.useEffect(() => {
    if (apartmentEditData.data !== null) {
      setApartmentData(apartmentEditData.data);
      let namee = ''
      const name = apartmentEditData.data.planMetric?.name?.split(',')
      const url = apartmentEditData.data.planMetric?.url?.split(',')
      for (let index = 0; index < name?.length; index++) {
        
        if(index < name.length -1 ){
          namee += name[index] + ',' + url[index] + ';'
        }
        else{
          namee += name[index] + ',' + url[index]
        }
      }
      setApartmentData((prev) => (
        {...prev,
        imgUrl: namee,
        imageData: apartmentEditData.data.imageUrl,
        }
      ))           
    }
  }, [apartmentEditData.data]);

  React.useEffect(() => {
    if (status === 'updateApartment_success') {
      toast.success(
        'Dokumenti u ruaj me sukses', {
          position: 'top-right',
          onClose: () => {
            //dispatch(getAllApartmentsById(buildId))
          }
        }
      )
      dispatch(setApartmentEditModalState(false));
      dispatch(resetStatusAndMsgJehona());
    }
    if (status === 'updateApartment_rejected') {
      toast.error(
        'Gabim ne ruajtjen e dokumentit', {
          position: 'top-right',
        }
      );
      dispatch(resetStatusAndMsgJehona());
    }
  }, [status]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let imageName = []
    let imagePath = []
    const imagePaths = apartmentData.imgUrl?.split(';')
    imagePaths?.forEach((item) => {
      const data = item.split(',')
      imageName.push(data[0])
      imagePath.push(data[1])
    })
    const formData = new FormData();
    formData.append("rooms", apartmentData.rooms);
    formData.append("isSold", apartmentData.isSold);
    formData.append("floor", apartmentData.floor);
    formData.append("square", apartmentData.square);
    formData.append("name", apartmentData.name);
    formData.append("apartmentNumber", apartmentData.apartmentNumber);
    formData.append("style", apartmentData.style);
    formData.append("className", apartmentData.className);
    formData.append("imageData", apartmentData.imageData);
    formData.append("path", apartmentData.path);
    formData.append("apartmentId", apartmentData.apartmentId);
    formData.append("balconySquare", apartmentData.balconySquare);
    formData.append("id", apartmentData.id);
    formData.append("planMetricName", imageName);
    formData.append("planMetricUrl", imagePath);
    dispatch(updateApartment(formData))
  };
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box flex={5}>
          <Grid container spacing={1} marginTop={1}>
            <Grid item sm={12} md={6}>
              <TextField
                fullWidth
                value={apartmentData.square}
                onChange={(e) => {
                  setApartmentData((prev) => ({
                    ...prev,
                    square: e.target.value,
                  }));
                }}
                name="square"
                label="Emri klientit"
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                fullWidth
                name="floor"
                value={apartmentData.floor}
                onChange={(e) => {
                  setApartmentData((prev) => ({
                    ...prev,
                    floor: e.target.value,
                  }));
                }}
                label="Mbiemri klientit"
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                fullWidth
                name="rooms"
                type="date"
                onChange={(e) => {
                  setApartmentData((prev) => ({
                    ...prev,
                    rooms: e.target.value,
                  }));
                }}
                label="Ditëlindja"
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                fullWidth
                name="name"
                value={apartmentData.name}
                onChange={(e) => {
                  setApartmentData((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                }}
                label="Numri Personal"
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                fullWidth
                name="apartmentNumber"
                value={apartmentData.apartmentNumber}
                onChange={(e) => {
                  setApartmentData((prev) => ({
                    ...prev,
                    apartmentNumber: e.target.value,
                  }));
                }}
                label="Email"
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                fullWidth
                name="balconySquare"
                value={apartmentData.balconySquare}
                onChange={(e) => {
                  setApartmentData((prev) => ({
                    ...prev,
                    balconySquare: e.target.value,
                  }));
                }}
                label="Numri Tel."
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                fullWidth
                name="apartmentId"
                label="ID e Apartamentit"
                value={apartmentData.apartmentId}
                onChange={(e) => {
                  setApartmentData((prev) => ({
                    ...prev,
                    apartmentId: e.target.value,
                  }));
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                fullWidth
                name="balconySquare"
                value={apartmentData.balconySquare}
                onChange={(e) => {
                  setApartmentData((prev) => ({
                    ...prev,
                    balconySquare: e.target.value,
                  }));
                }}
                label="Totali"
              />
            </Grid>
          </Grid>
        </Box>
        <Box display={"flex"} gap={2} mt={2}>
        <Button variant="contained" onClick={handleSubmit}>
          Ruaj
        </Button>
        <Button
          onClick={() => {
            dispatch(handleSalesModalState(false));
          }}
          variant="contained"
        >
          Anulo
        </Button>
      </Box>
      </Box>
    </Modal>
  );
}
