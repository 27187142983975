import { configureStore } from "@reduxjs/toolkit";
import { ApartmentDetailSlice, ApartmentEditSlice, AuthSlice, CalculatorSlice, FilterSlice, SalesSlice, WishListSlice } from "../features";
import ObjectsSlice from "../features/objects/ObjectsSlice";

export const store = configureStore({
  reducer: {
    AuthSlice: AuthSlice,
    WishlistSlice: WishListSlice,
    FilterSlice: FilterSlice,
    ObjectsSlice: ObjectsSlice,
    ApartmentEditSlice: ApartmentEditSlice,
    ApartmentDetailSlice: ApartmentDetailSlice,
    CalculatorSlice: CalculatorSlice,
    SalesSlice: SalesSlice,
  },
});
