import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Box, IconButton, Slider, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getPlanFilterData, handleMobilePlanFilterState, setPlanFilterLocation, setPlanFilterRoom, setPlanFilterSquare } from '../../../features/filter/FilterSlice';
import { colors, customFontFamily } from '../../../utils/theme';


const roomBoxButtonStyle = {
    marginTop: 1,
    userSelect: "none",
    ":hover": {
      backgroundColor: "rgba(5, 98, 0, 1)",
      cursor: "pointer",
      color: 'white'
    },
    ":active": {
      backgroundColor: "rgba(5, 98, 0, 1)",
      color: "white",
    },
  };

  const CustomSliderStyled = styled(Slider)`
  .MuiSlider-thumb {
    width: 20px;
    height: 20px;
    background-color: white;
    border:1px solid  #65984d;
    box-shadow: none;
  }
  .MuiSlider-thumb:hover{
    box-shadow: none;
  }
  .MuiSlider-thumb:active{
    box-shadow: none;
  }
  .MuiSlider-track {
    border: 1px solid #65984d);
  }
`;

const MobilePlanFilter = () => {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
  const filterData = useSelector(getPlanFilterData);
    const isSmallDev = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch = useDispatch();
  const handleChangeSquare = (event, newValue) => {
    dispatch(setPlanFilterSquare(newValue));
  };
    const handleLocation = (neighbor) => {
        dispatch(setPlanFilterLocation(neighbor))
    }
    const handleClose = () => {
    dispatch(handleMobilePlanFilterState(false));
    };
    return (
      <Dialog
        fullScreen={true}
        open={isSmallDev}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
        }}>
            <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            m: 1,
          }}
        >
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Box sx={{
          m: 1,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1
        }}>
            {filterData.rooms.map((item) => <Box sx={{
              p: 1,
              backgroundColor: colors.lightGreen,
              borderRadius: 5,
              px: 2,
              color: 'white'
            }}>{item}</Box>)}
            {filterData.location.map((item) => <Box sx={{
              p: 1,
              backgroundColor: colors.lightGreen,
              borderRadius: 5,
              px: 2,
              color: 'white'
            }}>{item}</Box>)}
            {(filterData.square.startVal > 40 || filterData.square.endVal < 300) && <Box sx={{
              p: 1,
              backgroundColor: colors.lightGreen,
              borderRadius: 5,
              px: 2,
              color: 'white'
            }}>{filterData.square.startVal}m<sup>2</sup> - {filterData.square.endVal}m<sup>2</sup></Box>}
          </Box>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            
            m: 1,
          }}
        >
          <Box>
            <Typography>Lokacioni</Typography>
            {/* <Divider sx={{
                    my: 1
                }} /> */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: 1,
                mt: 1,
              }}
            >
              <Box
                onClick={() => {
                  handleLocation("Jehona");
                }}
                sx={{
                  cursor: "pointer",
                  userSelect: "none",
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  p: 1,
                  borderRadius: 3,
                  borderColor: colors.lightGreen,
                  transition: "border 0.3s ease-in-out",
                  ":hover": {
                    border: "1px solid black",
                  },
                }}
              >
                <Typography fontFamily={customFontFamily} textAlign={"center"}>
                  Prishtina e re
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  handleLocation("Lagja Kalter");
                }}
                sx={{
                  cursor: "pointer",
                  userSelect: "none",
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  borderColor: colors.lightGreen,
                  p: 1,
                  borderRadius: 3,
                  transition: "border 0.3s ease-in-out",
                  ":hover": {
                    border: "1px solid black",
                  },
                }}
              >
                <Typography fontFamily={customFontFamily} textAlign={"center"}>
                  Rruga C
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  handleLocation("Tophane");
                }}
                sx={{
                  cursor: "pointer",
                  userSelect: "none",
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  p: 1,
                  borderRadius: 3,
                  borderColor: colors.lightGreen,
                  transition: "border 0.3s ease-in-out",
                  ":hover": {
                    border: "1px solid black",
                  },
                }}
              >
                <Typography fontFamily={customFontFamily} textAlign={"center"}>
                  Tophane
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              my: 3,
            }}
          >
            <Typography>Tipi</Typography>
            <Box display={"flex"} gap={1}>
              <Box
                width={40}
                height={40}
                border={"1px solid rgba(5, 98, 0, 1);"}
                bgcolor={
                  filterData?.rooms?.includes("1+1") ? "rgba(5, 98, 0, 1)" : ""
                }
                color={filterData?.rooms?.includes("1+1") ? "white" : "black"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"50px"}
                sx={{ ...roomBoxButtonStyle }}
                onClick={() => {
                  dispatch(setPlanFilterRoom("1+1"));
                }}
              >
                <Typography sx={{ fontFamily: "Montserrat", fontSize: "19px" }}>
                  1
                </Typography>
              </Box>
              <Box
                width={40}
                height={40}
                border={"1px solid rgba(5, 98, 0, 1);"}
                borderRadius={"50px"}
                bgcolor={
                  filterData?.rooms?.includes("2+1") ? "rgba(5, 98, 0, 1)" : ""
                }
                color={filterData?.rooms?.includes("2+1") ? "white" : "black"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={roomBoxButtonStyle}
                onClick={() => {
                  dispatch(setPlanFilterRoom("2+1"));
                }}
              >
                <Typography sx={{ fontFamily: "Montserrat", fontSize: "19px" }}>
                  2
                </Typography>
              </Box>

              <Box
                width={40}
                height={40}
                border={"1px solid rgba(5, 98, 0, 1);"}
                borderRadius={"50px"}
                bgcolor={
                  filterData?.rooms?.includes("3+1") ? "rgba(5, 98, 0, 1)" : ""
                }
                color={filterData?.rooms?.includes("3+1") ? "white" : "black"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={roomBoxButtonStyle}
                onClick={() => {
                  dispatch(setPlanFilterRoom("3+1"));
                }}
              >
                <Typography sx={{ fontFamily: "Montserrat", fontSize: "19px" }}>
                  3
                </Typography>
              </Box>
              <Box
                width={40}
                height={40}
                border={"1px solid rgba(5, 98, 0, 1);"}
                borderRadius={"50px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                bgcolor={
                  filterData?.rooms?.includes("4+1") ? "rgba(5, 98, 0, 1)" : ""
                }
                color={filterData?.rooms?.includes("4+1") ? "white" : "black"}
                sx={roomBoxButtonStyle}
                onClick={() => {
                  dispatch(setPlanFilterRoom("4+1"));
                }}
              >
                <Typography sx={{ fontFamily: "Montserrat", fontSize: "19px" }}>
                  4
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              my: 2,
            }}
          >
            <Typography fontFamily={"Montserrat"}>Sipërfaqja</Typography>
            <Box width={"95%"} ml={1}>
              <CustomSliderStyled
                onChange={handleChangeSquare}
                min={40}
                sx={{
                    color: '#65984d'
                }}
                max={300}
                value={[filterData.square.startVal, filterData.square.endVal]}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  {filterData.square.startVal}m<sup>2</sup>
                </Typography>
                <Typography>
                  {filterData.square.endVal}m<sup>2</sup>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        </Box>
        <Box>
          <Box sx={{
            m: 2,
            mb: 5,
            display: 'flex',
            alignItems: 'flex-end',
            gap: 1
          }}>
            <Box sx={{
              display: 'flex',
            alignItems: 'flex-end'
            }}>
            <Typography variant='h1' textAlign={'end'} fontFamily={'Montserrat'}>24</Typography>
            </Box>
            <Box mb={2}>
              <Typography>Apartamente</Typography>
              <Typography sx={{
                color: colors.lightGreen
              }}>Të Lira</Typography>
            </Box>
          </Box>
        <DialogActions sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <Button variant='outlined' fullWidth sx={{
            mx: 1,
            color: 'black',
            borderColor: colors.lightGreen,
            ':hover': {
              borderColor: colors.lightGreen,
            }
          }} onClick={handleClose}>
            Reseto
          </Button>
          <Button fullWidth sx={{
            mx: 1,
            backgroundColor: colors.lightGreen,
            ':hover': {
              backgroundColor: colors.lightGreen,
            },
            color: 'white'
          }} onClick={handleClose}>
            Filtro
          </Button>
        </DialogActions>
        </Box>  
        </Box>
        
      </Dialog>
    );
    }

export default MobilePlanFilter