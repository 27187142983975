import { Box, Divider, Popover, Typography } from '@mui/material'
import React from 'react'
import { apartmentPopover } from '../../../utils/theme';

const ApartmentPopUp = ( { anchorEl, item } ) => {
    const open = Boolean(anchorEl)
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      slotProps={{
        paper: {
          style: {
            backgroundColor: '#65984d'
          }
        }
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        pointerEvents: "none",
        "& .MuiPopover-paper": {
          borderRadius: "10px",
        },
        overflow: "visible",
      }}
    >
      <Box sx={{ ...apartmentPopover.container }}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          height={290}
          width={"100%"}
        >
          <Box display={"flex"} p={1}>
            <Typography sx={{ color: "rgba(255, 255, 255, 0.9999)" }} variant="h1">
              {item?.floorNumber && parseInt(item?.floorNumber)}
            </Typography>
            <Typography
              sx={{ color: "rgba(255, 255, 255, 0.9999)", mt: 2 }}
              variant="h5"
            >
              Kati
            </Typography>
          </Box>
          <Box width={"100%"}>
            <Divider
            sx={{
              mt: 5,
              border: '1px solid white'
            }}
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"} p={1}>
            <Box display={"flex"} gap={1}>
              <Typography fontWeight={700} variant="h6">
                Tipi
              </Typography>
              <Typography variant="h6">{item?.rooms}</Typography>
            </Box>
            <Box display={"flex"} gap={1}>
              <Typography fontWeight={700} variant="h6">
                Siperfaqja
              </Typography>
              <Typography variant="h6">
                {item && typeof item?.square === 'number' && parseFloat(item.square).toFixed(2)}<sup>2</sup>
              </Typography>
            </Box>
            <Box display={"flex"} gap={1}>
              <Typography fontWeight={700} variant="h6">
                Ndërtesa
              </Typography>
              <Typography variant="h6">A1</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Popover>
  );
}

export default ApartmentPopUp