import { TophanePlanMetricSvg, TophaneSvgHolder } from "./components";
import './App.css'
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { Jehona, Projects, ProjectsPlanMetric, SvgExtractor } from "./pages";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ThreeView from "./pages/ThreeView";
import JehonaApartments from "./pages/JehonaApartments";
import Calculator from "./components/calculator/Calculator";
import LoginPage from "./pages/LoginPage";

function App() {
  return (
    <div className="main">
       <ToastContainer />
       <Router>
      <Routes>
        <Route path="/tophane/kati/:id" element={<TophanePlanMetricSvg />} />
        <Route path="/tophane" element={<TophaneSvgHolder />} />
        <Route path="/svg-extractor" element={<SvgExtractor />} />
        <Route path="/" element={<Projects />} />
        <Route path="/plan" element={<ProjectsPlanMetric />} />
        <Route path="/jehona" element={<Jehona />} />
        <Route path="/jehona/building/:id" element={<JehonaApartments />} />
        <Route path="/jehona/360" element={<ThreeView />} />
        <Route path="/kalkulatori" element={<Calculator />} />
        <Route path="/admin" element={<LoginPage />} />
      </Routes>
      </Router>
    </div>
  );
}

export default App;
