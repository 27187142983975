import { Apartment, Bookmark, Close, Phone, PictureAsPdf, RemoveRedEye, Weekend } from '@mui/icons-material'
import { Box, Button, Dialog, Divider, Fade, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, {  useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkIfIsInWishList, getWishListItems, handleRefreshWishListState, handleWishlistItem } from '../../../features/wishList/WishListSlice'
import { getApartmentDetailModalData, getApartmentDetailModalState, handleApartmentDetailModalState } from '../../../features/apartment/ApartmentDetailSlice'
import { mainUrl } from '../../../utils/consts'
import Calculator from '../../calculator/Calculator'
import { apartmentDetailModal, customFontFamily } from '../../../utils/theme'
import { getCalculatorModalState, handleCalculatorModalState } from '../../../features/calculator/CalculatorSlice'
import TestImg from '../../../B1-nav.png';

const ApartmentDetailsModalSmallDev = () => {
    const [isWishList, setWishList] = useState(false);
    const wishListItems = useSelector(getWishListItems);
    const calculatorModalIsOpened = useSelector(getCalculatorModalState)
    const data = useSelector(getApartmentDetailModalData);
    const theme = useTheme()
    const open = useSelector(getApartmentDetailModalState)
    
    const [calculatorOpen, setCalculatorOpen] = useState(false)
    const dispatch = useDispatch()
    const isSmallDev = useMediaQuery(theme.breakpoints.down("md"));
    const [planMetricNames, setPlanMetricNames] = useState([])
    const id = 1;

  const handleWishList = () => {
    if(checkIfIsInWishList(id)) {
      const storedArray = JSON.parse(localStorage.getItem('wish')) || [];
    if(storedArray?.length > 0){
      const filteredList = storedArray.filter((item) => item !== id)
      
      localStorage.setItem('wish', JSON.stringify(filteredList))
      setWishList(false)
      dispatch(handleWishlistItem(wishListItems.filter((item) => item !== id)))
    }
    }
    else{
      const storedArray = JSON.parse(localStorage.getItem('wish')) || [];
    if(storedArray?.length > 0){
      storedArray.push(id)
      
      localStorage.setItem('wish', JSON.stringify(storedArray))
    }
    else{
      const wishListToAdd = [
        id
      ]
      localStorage.setItem('wish', JSON.stringify(wishListToAdd))
    }
    setWishList(true)
    let wishToAdd = [...wishListItems];
    wishToAdd.push(id)
    dispatch(handleWishlistItem(wishToAdd))
    }
    dispatch(handleRefreshWishListState())
  }
  useEffect(() => {
     if(checkIfIsInWishList(id)){
      setWishList(true)
     }
  }, [])
  useEffect(() => {
    setPlanMetricNames(data.planMetric?.name.split(','))
 }, [data])

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallDev ? '100%' : '100%',
    height: isSmallDev ? '100%' : '100vh',
    bgcolor: "background.paper",
    boxShadow: 40,
    p: isSmallDev ? 2 : 0,
    overflow: 'auto',
    
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          overflow: "hidden",
        },
      }}
      fullScreen
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={{ ...style, transition: "opacity 0.9s ease-in-out" }}>
          <Box
            sx={{
              ...apartmentDetailModal.main,
            }}
          >
            <Box
              sx={{
                ...apartmentDetailModal.container,
              }}
            >
              <Box
                sx={{
                  ...apartmentDetailModal.leftContainer,
                }}
              >
                <Box flex={1}></Box>
                <Box
                  sx={{
                    ...apartmentDetailModal.detailBoxContainer,
                  }}
                >
                  <Box
                    sx={{
                      ...apartmentDetailModal.detailBox,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mb: 2,
                        color: "black",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "black",
                          width: "90%",
                        }}
                      >
                        <Typography
                          fontWeight={"bold"}
                          variant="h1"
                          fontSize={"52px"}
                          fontFamily={"Montserrat"}
                        >
                          {typeof data.square === "number" &&
                            parseInt(data.square).toFixed(2)}{" "}
                          m<sup>2</sup>
                        </Typography>
                        <IconButton
                          sx={{
                            border: "1px solid black",
                            borderRadius: "50px",
                            color: "black",
                            my: 1,
                          }}
                          onClick={() =>
                            dispatch(handleApartmentDetailModalState(false))
                          }
                        >
                          <Close fontSize="large" />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                        width={"90%"}
                      >
                        <Divider />
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            color: "black",
                            mr: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Weekend fontSize="small" />
                            <Typography
                              fontFamily={customFontFamily}
                              fontSize={"14px"}
                            >
                              {"Dhoma".toUpperCase()}
                            </Typography>
                          </Box>
                          <Typography
                            fontWeight={"bold"}
                            fontFamily={customFontFamily}
                            fontSize={"14px"}
                          >
                            {data.rooms}
                          </Typography>
                        </Box>
                        <Divider />
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            color: "black",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Apartment fontSize="small" />
                            <Typography
                              fontFamily={customFontFamily}
                              fontSize={"14px"}
                            >
                              {"Kati".toUpperCase()}
                            </Typography>
                          </Box>
                          <Typography
                            fontWeight={"bold"}
                            fontFamily={customFontFamily}
                            fontSize={"14px"}
                          >
                            {data.floorNumber}
                          </Typography>
                        </Box>
                        <Divider />
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            color: "black",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Weekend fontSize="small" />
                            <Typography
                              fontFamily={customFontFamily}
                              fontSize={"14px"}
                            >
                              {"Ndërtesa ".toUpperCase()}
                            </Typography>
                          </Box>
                          <Typography
                            fontWeight={"bold"}
                            fontFamily={customFontFamily}
                            fontSize={"14px"}
                          >
                            1
                          </Typography>
                        </Box>
                        <Divider />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box height={"350px"}>
                <Box
                  sx={{
                    maxHeight: "350px",

                    display: "flex",
                    flexDirection: "column",

                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      display={"flex"}
                      width={"90%"}
                      justifyContent={"space-between"}
                      mt={2}
                    >
                      <Box
                        onClick={() => {
                          dispatch(handleCalculatorModalState(true));
                        }}
                        sx={{
                          ...apartmentDetailModal.calculateButton,
                        }}
                      >
                        <Typography fontSize={"14px"}>{"Kalkulo "}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                        }}
                      >
                        <IconButton
                          sx={{
                            border: "1px solid #2D4322",
                          }}
                        >
                          <Bookmark />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            window.open(`${mainUrl}objektetImg/pdf`, "_blank");
                          }}
                          sx={{
                            border: "1px solid #2D4322",
                            borderRadius: "50%",
                          }}
                        >
                          <PictureAsPdf />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="https://projektet-dp-partners.roitiv.com/objektetImg/testPlan.jpg"
                      width={"65%"}
                      height={"65%"}
                      style={{
                        objectFit: "fill",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "flex-end",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          ...apartmentDetailModal.contactUsButtonContainer,
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            mx: 2,
                          }}
                        >
                          <Button
                            startIcon={<RemoveRedEye />}
                            variant="contained"
                            sx={{
                              backgroundColor: "rgba(101, 152, 77, 0.5)",
                              border: "1px solid black",
                              fontFamily: "Montserrat",
                              padding: "5px 15px 5px 15px",
                              color: "black",
                              fontSize: "13px",
                              ":hover": {
                                backgroundColor: "rgba(101, 152, 77, 0.5)",
                              },
                            }}
                          >
                            Pamja 3D
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    <img style={{ opacity: 0.8 }} width={120} src={TestImg} />
                  </Box>
                  <Box
                    sx={{
                      ...apartmentDetailModal.contactUsButtonContainer,
                      mb: 4,
                    }}
                  >
                    <Box
                      sx={{
                        width: "90%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "rgba(107, 152, 85, 0.78)",
                          p: 1,
                          height: 35,
                          my: 2,
                          borderRadius: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          userSelect: "none",
                          ":hover": {
                            boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.1)",
                          },
                        }}
                      >
                        <Typography textAlign={"center"}>
                          Na Kontaktoni
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {calculatorModalIsOpened ? <Calculator /> : null}
        </Box>
      </Fade>
    </Dialog>
  );
}

export default ApartmentDetailsModalSmallDev
