import { Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Navbar from '../../navbar/Navbar';
import Filter from '../../filter/Filter';
import { ArrowBack } from '@mui/icons-material';
import ContextMenu from '../../contextMenu/ContextMenu';
import { getFloorFilter, handleFloorFilter } from '../../../features/filter/FilterSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllApartmentsByFloorAndBuilding } from '../../../features/objects/ObjectsApi';
import { getTophaneApartmentData } from '../../../features/objects/ObjectsSlice';
import Floor1 from '../components/tophane/SvgData';
import ObjectEditModal from '../../modal/ObjectEditModal';
import { getApartmentEditModal } from '../../../features/apartment/ApartmentEditSlice';
const PlanMetricSvg = () => {
    const {id} = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const floorFilter = useSelector(getFloorFilter);
    const data = useSelector(getTophaneApartmentData);
    const [apartment, setApartment] = useState(null);
    const apartmentEditModalState = useSelector(getApartmentEditModal);
    const [contextMenu, setContextMenu] = useState({
      anchorEl: null,
      data: null,
      id: null,
      open: false, 
      title: '',
      img: '',
      item: null,
    });
  
    const handleContextMenu = (item, e) => {
      console.log(e)
      setContextMenu({
        ...contextMenu,
        anchorEl: e.currentTarget,
        id: item?.id,
        data: item,
        open: true,
      })
    };

      useEffect(() => {
        dispatch(getAllApartmentsByFloorAndBuilding(14))
      }, [dispatch])

      useEffect(() => {
        dispatch(handleFloorFilter(parseInt(id)))
      }, [dispatch, id])

      useEffect(() => {
        if(data) {
          let a = []
          data?.forEach((item) => {
            if(parseInt(item.floorNumber) === floorFilter) {
              a.push(item)
            }
          })
          setApartment(a)
          
        }
      }, [data, floorFilter])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        bgcolor: "rgba(220, 195, 161, 0.08)",
      }}
    >
      <Navbar />
      <Box sx={{
        mt: 12,
        display: 'flex',
        px: 1,
        justifyContent: 'space-between'
      }}>
        <Typography sx={{
          fontSize: {
            xs: '20px',
            md: '42px'
          },
          fontWeight: 500
        }}>Kati {floorFilter}</Typography>
      <Button
        onClick={() => {
          navigate(-1);
        }}
        startIcon={<ArrowBack />}
        variant="contained"
        sx={{
          
          backgroundColor: "green",
          ":hover": {
            backgroundColor: "rgb(18, 89, 0)",
          },
          borderRadius: 6,
        }}
      >
        Kthehu
      </Button>
      </Box>
      <Floor1 apartments={apartment} handleContextMenu={handleContextMenu}/>

      <Box
        sx={{
          position: "fixed",
          bottom: {
            md: "15%",
            xs: 0,
          },
          width: {
            md: 80,
            xs: "100%",
          },
          height: {
            md: "60%",
            xs: 80,
          },
          bgcolor: {
            xs: "#6B9855C9",
            md: "rgba(220, 195, 161, 0.08)",
          },
          left: 0,
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Filter />
        {apartmentEditModalState.open && <ObjectEditModal />}
        <ContextMenu menu={contextMenu} setMenu={setContextMenu} />
      </Box>
    </Box>
  );
}

export default PlanMetricSvg