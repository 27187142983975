import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { handleLoginModalState } from '../features/auth/AuthSlice'
import Login from '../components/auth/Login'


const LoginPage = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(handleLoginModalState(true))
    }, [])
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: "linear-gradient(45deg, blue, red)",
      }}
    >
      <Login />
    </div>
  );
}

export default LoginPage