import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import React from 'react'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJehonaApartmentByBuilding } from '../../../../features/objects/ObjectsApi';
import { getJehonaApartmentData } from '../../../../features/objects/ObjectsSlice';
import { leftArrowButtonSvg, mainUrl, rightArrowButtonSvg } from '../../../../utils/consts';
import ApartmentPopUp from '../../../popover/jehona/ApartmentPopUp';
import { useState } from 'react';
import { getFilterData } from '../../../../features/filter/FilterSlice';
import IconWrapper from '../../../common/IconWrapper';
import ContextMenu from '../../../contextMenu/ContextMenu';
import { isAuthorized } from '../../../../features/auth/AuthSlice';
import { getApartmentEditModal } from '../../../../features/apartment/ApartmentEditSlice';
import ObjectEditModal from '../../../modal/ObjectEditModal';
import { handleApartmentDetailModalData, handleApartmentDetailModalState } from '../../../../features/apartment/ApartmentDetailSlice';
import { getSalesModalState } from '../../../../features/apartment/sales/SalesSlice';
import SalesModal from '../../../modal/SalesModal';
import { colors } from '../../../../utils/theme';

const SvgHolder = () => {
 const { id } = useParams();
 const dispatch = useDispatch()
 const apartmentSvgData = useSelector(getJehonaApartmentData);
 const apartmentEditModalState = useSelector(getApartmentEditModal);
 const [currentIndex, setCurrentIndex] = useState(0);
 const salesModalIsOpen = useSelector(getSalesModalState)
 const theme = useTheme();
 const isSmallDev = useMediaQuery(theme.breakpoints.down('md'));
 const filter = useSelector(getFilterData);
 const [menu, setMenu] = useState({
    open: false,
    anchorEl: null,
    data: null
  });
 const [popoverData, setPopoverData] = useState({
    anchorEl: null,
    data: null,
 });

 const handlePrev = () => {
  setCurrentIndex((prevIdx) => Math.max(prevIdx - 1, 0));
  if(currentIndex === 0) {
    setCurrentIndex(apartmentSvgData.length - 1);
  }
};
const handleNext = () => {
  setCurrentIndex((prevIdx) => Math.min(prevIdx + 1, apartmentSvgData.length - 1));
  if(currentIndex === apartmentSvgData.length - 1){
    setCurrentIndex(0);
  }
};

 const handleMouseEnter = (e, item) => {
    if(!isSmallDev)
        setPopoverData({
          anchorEl: e.currentTarget,
          data: item,
        });
 }
 const handleMouseLeave = () => {
    if(!isSmallDev)
        setPopoverData({
            anchorEl: null,
            data: null,
        })
 }
 const handlePathClick = ({e, item}) => {
  console.log(item)
    dispatch(handleApartmentDetailModalData(item))
    //client modal
    dispatch(handleApartmentDetailModalState(true))
  }
  const handleContextMenu = ({e, item}) => {
    
    if(isAuthorized()){
      setMenu(
        {
          open: true,
          anchorEl: e.currentTarget,
          data: item,
        }
      )
    }
  }
 useEffect(() => {
    dispatch(getJehonaApartmentByBuilding(id))
 }, [id, dispatch])
  return (
    <Box sx={{
        width: '100%',
        height: '100%',
        overflowX: 'scroll',
        mt: 2,
    }}>
        <IconButton
        onClick={handlePrev}
         sx={{
            position: 'fixed',
            top: '50%',
            left: 5,
            border: `1px solid ${colors.lightGreen}`,
         }}
        >
            <IconWrapper color={colors.lightGreen} svg={leftArrowButtonSvg}/>
        </IconButton>
        <IconButton
        onClick={handleNext}
         sx={{
            position: 'fixed',
            top: '50%',
            right: 5,
            border: `1px solid ${colors.lightGreen}`
         }}
        >
            <IconWrapper color={colors.lightGreen} svg={rightArrowButtonSvg}/>
        </IconButton>
        {apartmentSvgData?.map((item, index) => {
          
            return (
              <div
                key={item.buildingName}
                style={{
                  height: index !== currentIndex && "0px",
                  opacity: currentIndex === index ? 1 : 0,
                  transition: "opacity 0.3s ease-in-out",
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
          >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                width={isSmallDev ? "250%" : "100%"}
                
                y="0px"
                viewBox={item.viewBoxStyle}
                xmlSpace="preserve"
              >
                <image
                  width={item.imgWidth}
                  height={item.imgHeight}
                  transform={item.imgTransform}
                  xlinkHref={`${mainUrl}${item.imgUrl}`}
                />
                {item.apartmentList?.map((pathsData) => {
                  if (pathsData.pointsType === "path") {
                    return (
                      <path
                        onContextMenu={(e) => {
                         handleContextMenu({e, item: pathsData})
                        }}
                        onClick={(e) => {
                          if (
                            pathsData.square <= filter.square.endVal &&
                            pathsData.square >= filter.square.startVal &&
                            parseInt(parseInt(pathsData.floorNumber)) <= filter.floor.endVal &&
                            parseInt(parseInt(pathsData.floorNumber)) >= filter.floor.startVal &&
                            (filter.rooms.includes(pathsData.rooms) ||
                            filter.rooms.includes("all")) &&
                            pathsData.isSold === false
                          ) {
                            handlePathClick({ e, item: pathsData });
                          }
                        }}
                        onMouseEnter={(e) => {
                          if (
                            pathsData.square <= filter.square.endVal &&
                            pathsData.square >= filter.square.startVal &&
                            parseInt(pathsData.floorNumber) <= filter.floor.endVal &&
                            parseInt(pathsData.floorNumber) >= filter.floor.startVal &&
                            (filter.rooms.includes(pathsData.rooms) ||
                              filter.rooms.includes("all")) &&
                            pathsData.isSold === false
                          )
                            handleMouseEnter(e, pathsData);
                        }}
                        onMouseLeave={handleMouseLeave}
                        className={
                          pathsData.isSold === true
                            ? parseInt(parseInt(pathsData.floorNumber)) <= filter.floor.endVal &&
                            parseInt(parseInt(pathsData.floorNumber)) >= filter.floor.startVal
                              ? "st3"
                              : "st1"
                            : pathsData.square <= filter.square.endVal &&
                              pathsData.square >= filter.square.startVal &&
                              parseInt(parseInt(pathsData.floorNumber)) <= filter.floor.endVal &&
                              parseInt(parseInt(pathsData.floorNumber)) >= filter.floor.startVal &&
                              (filter.rooms.includes(pathsData.rooms) ||
                                filter.rooms.includes("all")) &&
                              pathsData.isSold === false
                            ? "st3"
                            : "st4"
                        }
                        d={pathsData.path}
                      />
                    );
                  }
                  if (pathsData.pointsType === "polygon") {
                    return (
                      <polygon
                        onContextMenu={(e) => {
                            handleContextMenu({e, item: pathsData})
                            }}
                        onClick={(e) => {
                          if (
                            pathsData.square <= filter.square.endVal &&
                            pathsData.square >= filter.square.startVal &&
                            parseInt(pathsData.floorNumber) <= filter.floor.endVal &&
                            parseInt(pathsData.floorNumber) >= filter.floor.startVal &&
                            (filter.rooms.includes(pathsData.rooms) ||
                              filter.rooms.includes("all")) &&
                            pathsData.isSold === false
                          ) {
                            handlePathClick({ e, item: pathsData });
                          }
                        }}
                        onMouseEnter={(e) => {
                          if (
                            pathsData.square <= filter.square.endVal &&
                            pathsData.square >= filter.square.startVal &&
                            parseInt(pathsData.floorNumber) <= filter.floor.endVal &&
                            parseInt(pathsData.floorNumber) >= filter.floor.startVal &&
                            (filter.rooms.includes(pathsData.rooms) ||
                              filter.rooms.includes("all")) &&
                            pathsData.isSold === false
                          )
                            handleMouseEnter(e, pathsData);
                        }}
                        onMouseLeave={handleMouseLeave}
                        className={
                          pathsData.isSold === true
                            ? parseInt(pathsData.floorNumber) <= filter.floor.endVal &&
                              parseInt(pathsData.floorNumber) >= filter.floor.startVal
                              ? "st3"
                              : "st1"
                            : pathsData.square <= filter.square.endVal &&
                              pathsData.square >= filter.square.startVal &&
                              parseInt(pathsData.floorNumber) <= filter.floor.endVal &&
                              parseInt(pathsData.floorNumber) >= filter.floor.startVal &&
                              (filter.rooms.includes(pathsData.rooms) ||
                                filter.rooms.includes("all")) &&
                              pathsData.isSold === false
                            ? "st3"
                            : "st4"
                        }
                        points={pathsData.path}
                      />
                    );
                  }
                })}
              </svg>
              </div>
            );
        })}
        <ApartmentPopUp anchorEl={popoverData.anchorEl} item={popoverData.data} />
        {menu.open ? <ContextMenu menu={menu} setMenu={setMenu} /> : null}
        {apartmentEditModalState.open ? <ObjectEditModal /> : null}
        {salesModalIsOpen ? <SalesModal /> : null}
    </Box>
  )
}

export default SvgHolder