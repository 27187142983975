import { Box, Popover } from '@mui/material'
import React from 'react'
import { jehonaFilterPopover } from '../../../utils/theme';

const FilterCard = ( { controls, children } ) => {
    const open = Boolean(controls.popover.anchorEl)
    const handleClose = () => {
        controls.setPopover({
            anchorEl: null
        })
    }
    return (
    <Popover
        open={open}
        onClose={handleClose}
        anchorEl={controls.popover.anchorEl}
        anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
        }}
        transformOrigin={{
        vertical: "top",
        horizontal: "center",
        }}
        sx={{
        "& .MuiPopover-paper": {
            borderRadius: "10px",
        },
        height: 350,
        overflow: 'visible'
        }}
    >
        
        <Box sx={{ ...jehonaFilterPopover.container }}>
            {children}
        </Box>
    </Popover>
    );
}
export default FilterCard