import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getPlanFilterData, setPlanFilterRoom } from '../../../../features/filter/FilterSlice';
import { Box, Typography } from '@mui/material';

const roomBoxButtonStyle = {
    marginTop: 1,
    userSelect: "none",
    ":hover": {
      backgroundColor: "rgba(5, 98, 0, 1)",
      cursor: "pointer",
      color: 'white'
    },
    ":active": {
      backgroundColor: "rgba(5, 98, 0, 1)",
      color: "white",
    },
  };

const CustomPlanRoomFilter = () => {
  const dispatch = useDispatch();
  const filterData = useSelector(getPlanFilterData);
  return (
    <Box
      p={1}
      display={"flex"}
      flexDirection={'column'}
      width={'100%'}
      gap={1}
    >
      <Box
        width={'95%'}
        height={40}
        border={"1px solid rgba(5, 98, 0, 1);"}
        bgcolor={filterData?.rooms?.includes("1+1") ? "rgba(5, 98, 0, 1)" : ""}
        color={filterData?.rooms?.includes("1+1") ? "white" : "black"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        borderRadius={"10px"}
        sx={{ ...roomBoxButtonStyle }}
        onClick={() => {
          dispatch(setPlanFilterRoom("1+1"));
        }}
      >
        <Typography sx={{ fontFamily: "Montserrat", fontSize: "19px" }}>
          Tipi 1+1
        </Typography>
      </Box>
      <Box
         width={'95%'}
        height={40}
        border={"1px solid rgba(5, 98, 0, 1);"}
        borderRadius={"10px"}
        bgcolor={filterData?.rooms?.includes("2+1") ? "rgba(5, 98, 0, 1)" : ""}
        color={filterData?.rooms?.includes("2+1") ? "white" : "black"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={roomBoxButtonStyle}
        onClick={() => {
          dispatch(setPlanFilterRoom("2+1"));
        }}
      >
        <Typography sx={{ fontFamily: "Montserrat", fontSize: "19px" }}>
          Tipi 2+1
        </Typography>
      </Box>

      <Box
         width={'95%'}
        height={40}
        border={"1px solid rgba(5, 98, 0, 1);"}
        borderRadius={"10px"}
        bgcolor={filterData?.rooms?.includes("3+1") ? "rgba(5, 98, 0, 1)" : ""}
        color={filterData?.rooms?.includes("3+1") ? "white" : "black"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={roomBoxButtonStyle}
        onClick={() => {
          dispatch(setPlanFilterRoom("3+1"));
        }}
      >
        <Typography sx={{ fontFamily: "Montserrat", fontSize: "19px" }}>
          Tipi 3+1
        </Typography>
      </Box>
      <Box
         width={'95%'}
        height={40}
        border={"1px solid rgba(5, 98, 0, 1);"}
        borderRadius={"10px"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        bgcolor={filterData?.rooms?.includes("4+1") ? "rgba(5, 98, 0, 1)" : ""}
        color={filterData?.rooms?.includes("4+1") ? "white" : "black"}
        sx={roomBoxButtonStyle}
        onClick={() => {
          dispatch(setPlanFilterRoom("4+1"));
        }}
      >
        <Typography sx={{ fontFamily: "Montserrat", fontSize: "19px" }}>
          Tipi 4+1
        </Typography>
      </Box>
    </Box>
  );
}

export default CustomPlanRoomFilter