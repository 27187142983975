import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Box, IconButton, Slider, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterData, getFilterState, handleFilterState, handleMobileModal, handleResetFilter, handleResetFilterPlan, setFilterFloor, setFilterRoom, setFilterSquare } from '../../../features/filter/FilterSlice';
import { colors } from '../../../utils/theme';
import { getApartmentSvgData } from '../../../features/objects/ObjectsSlice';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';


const roomBoxButtonStyle = {
    marginTop: 1,
    userSelect: "none",
    ":hover": {
      backgroundColor: "rgba(5, 98, 0, 1)",
      cursor: "pointer",
      color: 'white'
    },
    ":active": {
      backgroundColor: "rgba(5, 98, 0, 1)",
      color: "white",
    },
  };

  const CustomSliderStyled = styled(Slider)`
  .MuiSlider-thumb {
    width: 20px;
    height: 20px;
    background-color: white;
    border:1px solid  #65984d;
    box-shadow: none;
  }
  .MuiSlider-thumb:hover{
    box-shadow: none;
  }
  .MuiSlider-thumb:active{
    box-shadow: none;
  }
  .MuiSlider-track {
    border: 1px solid #65984d);
  }
`;

const MobileFilter = ( { apartment } ) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const { id } = useParams()
  const filterData = useSelector(getFilterData);
  const filterState = useSelector(getFilterState);
  const isSmallDev = useMediaQuery(theme.breakpoints.down('md'));
  const apartmentData = useSelector(getApartmentSvgData);
  const dispatch = useDispatch();
  const [apartments, setApartments] = useState(null);

  const handleChangeSquare = (event, newValue) => {
    dispatch(setFilterSquare(newValue));
    dispatch(handleFilterState(!filterState))
  };
  const handleChangeFloor = (event, newValue) => {
    dispatch(setFilterFloor(newValue));
    dispatch(handleFilterState(!filterState))
  };
    const handleClose = () => {
      dispatch(handleMobileModal(false))
    };
    useEffect(() => {console.log(id)}, [id])
    useEffect(() => {
      const groupedData = apartmentData?.reduce((acc, cur) => {
        if (!acc[cur.buildingNr]) {
          acc[cur.buildingNr] = {
            count: 0,
          };
        }
        if (cur.square <= filterData.square.endVal && cur.square >=  filterData.square.startVal &&
          cur.floorNumber <= filterData.floor.endVal && cur.floorNumber >= filterData.floor.startVal && 
          (filterData.rooms.includes(cur.rooms) || filterData.rooms.includes('all')) &&
          cur.isSold === false) {
          acc[cur.buildingNr].count += 1;
        }
        return acc;
      }, {});
      if(groupedData){
        setApartments(prevState => ({ ...prevState, ...groupedData }));
        console.log(groupedData)
      }
  }, [apartmentData, filterData])

    return (
      <Dialog
        fullScreen={true}
        open={isSmallDev}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              m: 1,
            }}
          >
            <IconButton onClick={() => dispatch(handleMobileModal(false))}>
              <Close />
            </IconButton>
          </Box>
          <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            mx: 1,
            gap: 1,
          }}
          >
            {filterData.rooms.map((item) => <Box sx={{
              p: 1,
              backgroundColor: colors.lightGreen,
              borderRadius: 5,
              px: 2,
              color: 'white'
            }}>{item}</Box>)}
            {(filterData.floor.startVal > 1 || filterData.floor.endVal < 10) && <Box sx={{
              p: 1,
              backgroundColor: colors.lightGreen,
              borderRadius: 5,
              px: 2,
              color: 'white'
            }}>{filterData.floor.startVal} - {filterData.floor.endVal}</Box>}
            {(filterData.square.startVal > 40 || filterData.square.endVal < 300) && <Box sx={{
              p: 1,
              backgroundColor: colors.lightGreen,
              borderRadius: 5,
              px: 2,
              color: 'white'
            }}>{filterData.square.startVal}m<sup>2</sup> - {filterData.square.endVal}m<sup>2</sup></Box>}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                m: 1,
              }}
            >
              <Box
                sx={{
                  my: 3,
                }}
              >
                <Typography>Tipi</Typography>
                <Box display={"flex"} gap={1}>
                  <Box
                    width={40}
                    height={40}
                    border={"1px solid rgba(5, 98, 0, 1);"}
                    bgcolor={
                      filterData?.rooms?.includes("1+1")
                        ? "rgba(5, 98, 0, 1)"
                        : ""
                    }
                    color={
                      filterData?.rooms?.includes("1+1") ? "white" : "black"
                    }
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    borderRadius={"50px"}
                    sx={{ ...roomBoxButtonStyle }}
                    onClick={() => {
                      dispatch(setFilterRoom("1+1"));
                    }}
                  >
                    <Typography
                      sx={{ fontFamily: "Montserrat", fontSize: "19px" }}
                    >
                      1
                    </Typography>
                  </Box>
                  <Box
                    width={40}
                    height={40}
                    border={"1px solid rgba(5, 98, 0, 1);"}
                    borderRadius={"50px"}
                    bgcolor={
                      filterData?.rooms?.includes("2+1")
                        ? "rgba(5, 98, 0, 1)"
                        : ""
                    }
                    color={
                      filterData?.rooms?.includes("2+1") ? "white" : "black"
                    }
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={roomBoxButtonStyle}
                    onClick={() => {
                      dispatch(setFilterRoom("2+1"));
                    }}
                  >
                    <Typography
                      sx={{ fontFamily: "Montserrat", fontSize: "19px" }}
                    >
                      2
                    </Typography>
                  </Box>

                  <Box
                    width={40}
                    height={40}
                    border={"1px solid rgba(5, 98, 0, 1);"}
                    borderRadius={"50px"}
                    bgcolor={
                      filterData?.rooms?.includes("3+1")
                        ? "rgba(5, 98, 0, 1)"
                        : ""
                    }
                    color={
                      filterData?.rooms?.includes("3+1") ? "white" : "black"
                    }
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={roomBoxButtonStyle}
                    onClick={() => {
                      dispatch(setFilterRoom("3+1"));
                    }}
                  >
                    <Typography
                      sx={{ fontFamily: "Montserrat", fontSize: "19px" }}
                    >
                      3
                    </Typography>
                  </Box>
                  <Box
                    width={40}
                    height={40}
                    border={"1px solid rgba(5, 98, 0, 1);"}
                    borderRadius={"50px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    bgcolor={
                      filterData?.rooms?.includes("4+1")
                        ? "rgba(5, 98, 0, 1)"
                        : ""
                    }
                    color={
                      filterData?.rooms?.includes("4+1") ? "white" : "black"
                    }
                    sx={roomBoxButtonStyle}
                    onClick={() => {
                      dispatch(setFilterRoom("4+1"));
                    }}
                  >
                    <Typography
                      sx={{ fontFamily: "Montserrat", fontSize: "19px" }}
                    >
                      4
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography>Kati</Typography>
                {/* <Divider sx={{
                    my: 1
                }} /> */}
                <Box width={'95%'} ml={1}>
                <CustomSliderStyled
                  onChange={handleChangeFloor}
                  min={1}
                  sx={{
                    color: "#65984d",
                  }}
                  max={10}
                  value={[filterData.floor.startVal, filterData.floor.endVal]}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>{filterData.floor.startVal}</Typography>
                  <Typography>{filterData.floor.endVal}</Typography>
                </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  my: 2,
                }}
              >
                <Typography fontFamily={"Montserrat"}>Sipërfaqja</Typography>
                <Box width={"95%"} ml={1}>
                  <CustomSliderStyled
                    onChange={handleChangeSquare}
                    min={40}
                    sx={{
                      color: "#65984d",
                    }}
                    max={300}
                    value={[
                      filterData.square.startVal,
                      filterData.square.endVal,
                    ]}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>
                      {filterData.square.startVal}m<sup>2</sup>
                    </Typography>
                    <Typography>
                      {filterData.square.endVal}m<sup>2</sup>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          { apartment && <Box sx={{
            m: 1,
            mb: 5,
            display: 'flex',
            alignItems: 'flex-end',
            gap: 1
          }}>
            <Box sx={{
              display: 'flex',
            alignItems: 'flex-end'
            }}>
            <Typography variant='h1' textAlign={'end'} fontFamily={'Montserrat'}>{apartments && apartments[id]?.count}</Typography>
            </Box>
            <Box mb={2}>
              <Typography>Apartamente</Typography>
              <Typography sx={{
                color: colors.lightGreen
              }}>Të Lira</Typography>
            </Box>
          </Box>}
          <Box>
            <DialogActions sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mx: 1
            }}>
              <Button variant='outlined' fullWidth sx={{
            
            color: 'black',
            borderRadius: '50px',
            borderColor: colors.lightGreen,
            ':hover': {
              borderColor: colors.lightGreen,
            }
          }} onClick={() => {
            dispatch(handleResetFilter())
            handleClose()
          }}>
            Reseto
          </Button>
          <Button fullWidth sx={{
            
            borderRadius: '50px',
            backgroundColor: colors.lightGreen,
            ':hover': {
              backgroundColor: colors.lightGreen,
            },
            color: 'white'
          }} onClick={handleClose}>
            Filtro
          </Button>
            </DialogActions>
          </Box>
        </Box>
      </Dialog>
    );
    }

export default MobileFilter