import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    data: {},
    state: 'idle',
    isOpen: false,
    msg: '',
}

const ApartmentDetailSlice = createSlice({
    name: 'ApartmentDetailSlice',
    initialState,
    reducers: {
        handleApartmentDetailModalData(state, action) {
            state.data = action.payload;
        },
        handleApartmentDetailModalState(state, action) {
            state.isOpen = action.payload
        }
    }
})
export const {
    handleApartmentDetailModalData,
    handleApartmentDetailModalState
} = ApartmentDetailSlice.actions;
export const getApartmentDetailModalState = (state) => state.ApartmentDetailSlice.isOpen;
export const getApartmentDetailModalData = (state) => state.ApartmentDetailSlice.data
export default ApartmentDetailSlice.reducer;