import { Box } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import Navbar from '../../navbar/Navbar'
import SvgHolder from './components/SvgHolder'
import FilterBox from '../../filter/jehona/FilterBox'
import ApartmentDetailsModal from '../../modal/ApartmentDetailsModal'
import { getApartmentDetailModalState } from '../../../features/apartment/ApartmentDetailSlice'

const RegularDevice = () => {
  const detailsModalState = useSelector(getApartmentDetailModalState)
  return (
    <Box>
        <Navbar />
        <Box sx={{
            mt: 12,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        }}>
        <Box sx={{
          width: {
            xs: '100%',
            md: '95%',
            xl: '90%'
          }
          }}>
            <FilterBox />
            <SvgHolder />
          </Box>
        </Box>
        
        {detailsModalState ? <ApartmentDetailsModal /> : null}
    </Box>
  )
}
export default RegularDevice