import { Pannellum } from 'pannellum-react'
import React, { useState } from 'react'
import { Box, Button } from '@mui/material'
import image1 from './../components/svgHolder/jehona/images/1-Panorama.jpg'
import image2 from './../components/svgHolder/jehona/images/2-Panorama.jpg'
import image3 from './../components/svgHolder/jehona/images/3-Panorama.jpg'
import image4 from './../components/svgHolder/jehona/images/4-Panorama.jpg'
import image5 from './../components/svgHolder/jehona/images/5-Panorama.jpg'
import image6 from './../components/svgHolder/jehona/images/6-Panorama.jpg'
import image7 from './../components/svgHolder/jehona/images/7-Panorama.jpg'
import image8 from './../components/svgHolder/jehona/images/8-Panorama.jpg'
import image9 from './../components/svgHolder/jehona/images/9-Panorama.jpg'
import image10 from './../components/svgHolder/jehona/images/10-Panorama.jpg'
import image11 from './../components/svgHolder/jehona/images/11-Panorama.jpg'
import image12 from './../components/svgHolder/jehona/images/12-Panorama.jpg'
import image13 from './../components/svgHolder/jehona/images/13-Panorama.jpg'
import image15 from './../components/svgHolder/jehona/images/15-Panorama.jpg'
import image16 from './../components/svgHolder/jehona/images/16-Panorama.jpg'
import image17 from './../components/svgHolder/jehona/images/17-Panorama.jpg'
import Navbar from '../components/navbar/Navbar'

const Scene = {
  image1: {
    image: image1,
    title: "Pamja 1",
    pitch: 10,
    yaw: 180,
    hotspots: [
      {
        pitch: -3,
        yaw: 10,
        nextScene: "image3",
      },
      { pitch: -4, yaw: -125, nextScene: "image4" },
    ],
  },
  image2: {
    image: image2,
    title: "Pano 2",
    pitch: 10,
    yaw: 180,
    hotspots: [
      {
        pitch: -3,
        yaw: -7,
        nextScene: "image3",
      },
      { pitch: 2, yaw: -100, nextScene: "image8" },
      { pitch: -3, yaw: 73, nextScene: "image5" },
    ],
  },
  image3: {
    image: image3,
    title: "Pano 3",
    pitch: 10,
    yaw: 180,
    hotspots: [
      {
        pitch: -5,
        yaw: -20,
        nextScene: "image1",
      },
      {
        pitch: -4,
        yaw: 80,
        nextScene: "image2",
      },
    ],
  },
  image4: {
    image: image4,
    title: "Pano 4",
    pitch: 10,
    yaw: 180,
    hotspots: [
      {
        pitch: -3,
        yaw: 10,
        nextScene: "image1",
      },
      { pitch: -3, yaw: -147, nextScene: "image6" },
    ],
  },
  image5: {
    image: image5,
    title: "Pano 5",
    pitch: 10,
    yaw: 180,
    hotspots: [
      {
        pitch: -3,
        yaw: 70,
        nextScene: "image6",
      },
      { pitch: -3, yaw: -85, nextScene: "image2" },
    ],
  },
  image6: {
    image: image6,
    title: "Pano 6",
    pitch: 10,
    yaw: 180,
    hotspots: [
      {
        pitch: -3,
        yaw: 10,
        nextScene: "image4",
      },
      {
        pitch: -8,
        yaw: 133,
        nextScene: "image12",
      },
      { pitch: -3, yaw: -75, nextScene: "image5" },
    ],
  },
  image7: {
    image: image7,
    title: "Pano 7",
    pitch: 10,
    yaw: 180,
  },
  image8: {
    image: image8,
    title: "Pano 8",
    pitch: 10,
    yaw: 180,
    hotspots: [
      {
        pitch: -3,
        yaw: -45,
        nextScene: "image9",
      },
      { pitch: -3, yaw: 30, nextScene: "image2" },
    ],
  },
  image9: {
    image: image9,
    title: "Pano 9",
    pitch: 10,
    yaw: 180,
    hotspots: [
      {
        pitch: -3,
        yaw: -85,
        nextScene: "image10",
      },
      { pitch: -3, yaw: 85, nextScene: "image8" },
    ],
  },
  image10: {
    image: image10,
    title: "Pano 10",
    pitch: 10,
    yaw: 180,
    hotspots: [
      {
        pitch: -3,
        yaw: -115,
        nextScene: "image15",
      },
      { pitch: -3, yaw: 65, nextScene: "image9" },
    ],
  },
  image11: {
    image: image11,
    title: "Pano 11",
    pitch: 10,
    yaw: 180,
    hotspots: [
      {
        pitch: -3,
        yaw: -75,
        nextScene: "image12",
      },
      { pitch: -2, yaw: 80, nextScene: "image13" },
    ],
  },
  image12: {
    image: image12,
    title: "Pano 12",
    pitch: 10,
    yaw: 180,
    hotspots: [
      {
        pitch: -3,
        yaw: -45,
        nextScene: "image6",
      },
      { pitch: -3, yaw: 45, nextScene: "image11" },
    ],
  },
  image16: {
    image: image13,
    title: "Pano 13",
    pitch: 10,
    yaw: 180,
  },
  image15: {
    image: image15,
    title: "Pano 13",
    pitch: 10,
    yaw: 180,
    hotspots: [
      {
        pitch: -3,
        yaw: -80,
        nextScene: "image13",
      },
      { pitch: -3, yaw: 80, nextScene: "image10" },
    ],
  },
  image13: {
    image: image16,
    title: "Pano 16",
    pitch: 10,
    yaw: 180,
    hotspots: [
      {
        pitch: -3,
        yaw: 2,
        nextScene: "image11",
      },
      { pitch: -2, yaw: 130, nextScene: "image15" },
    ],
  },
  image17: {
    image: image17,
    title: "Pano 17",
    pitch: 10,
    yaw: 180,
    hotspots: [
      {
        pitch: -3,
        yaw: -50,
        nextScene: "image6",
      },
      { pitch: -2, yaw: 60, nextScene: "image13" },
    ],
  },
};

const ThreeView = () => {
    const [selected, setSelected] = useState(Scene.image6)
  return (
    <Box>
        <Navbar />
        <Box>
    <Pannellum
        width="100%"
        height="100vh"
        image={selected.image}
        pitch={0}
        title={selected.title}
        yaw={0}
        hfov={110}
        autoLoad
        showControls={false}
      >
      {
        selected.hotspots?.map((item) => {
            return (
                <Pannellum.Hotspot
                type="custom"
                pitch={item.pitch}
                yaw={item.yaw}
                handleClick={(evt, name) => setSelected(Scene[item.nextScene])}
                />
            )
        })
      }
      </Pannellum>
      <Button variant='contained' sx={{
        position: 'fixed',
        bottom: 0,
        right: 1
      }} onClick={() => {
        setSelected(Scene.image1)
      }}>Back</Button>
      
        </Box>
    </Box>
  )
}

export default ThreeView