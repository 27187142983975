import { Box, Slider, Typography, styled } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getPlanFilterData, setPlanFilterSquare } from '../../../../features/filter/FilterSlice';

const CustomSliderStyled = styled(Slider)`
  .MuiSlider-thumb {
    width: 20px;
    height: 20px;
    background-color: white;
    border:1px solid rgba(5, 98, 0, 1);
    box-shadow: none;
  }
  .MuiSlider-thumb:hover{
    box-shadow: none;
  }
  .MuiSlider-thumb:active{
    box-shadow: none;
  }
  .MuiSlider-track {
    border: 1px solid rgba(5, 98, 0, 1);
  }
`;
const CustomSquareFilter = () => {
    const dispatch = useDispatch();
  const filterData = useSelector(getPlanFilterData);

  const handleChangeSquare = (event, newValue) => {
    dispatch(setPlanFilterSquare(newValue));
  };
  return (
    <Box p={3} width={"100%"}>
      <Typography
        fontFamily={"Montserrat"}
        fontSize={"20px"}
        sx={{
          ml: -1,
        }}
      >
        {"Sipërfaqja"}
      </Typography>
      <CustomSliderStyled
        onChange={handleChangeSquare}
        min={40}
        max={300}
        value={[filterData.square.startVal, filterData.square.endVal]}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          {filterData.square.startVal}m<sup>2</sup>
        </Typography>
        <Typography>
          {filterData.square.endVal}m<sup>2</sup>
        </Typography>
      </Box>
    </Box>
  );
}

export default CustomSquareFilter