import { createSlice } from "@reduxjs/toolkit"
import { getAllApartmentsByFloorAndBuilding, getApartmentsAllData, getApartmentsSvgDataAll, getJehonaApartmentsByBuilding, getObjectSvgDataAll, updateApartment } from "./ObjectsApi";

const initialState = {
    filteredCard: '',
    apartmentSvgData: null,
    objectSvgData: [],
    msg: '',
    statusObjects: 'idle',
    statusApartment: 'idle',
    detailModalState: false,
    detailModalData: {},
    jehonaApartmentData: [],
    jehonaApartmentStatus: 'idle',
    jehonaApartmentMsg: '',
    tophaneApartmentData: [],
    tophaneApartmentStatus: 'idle',
    tophaneApartmentMsg: '',
}

const ObjectsSlice = createSlice({
    name: 'ObjectsSlice',
    initialState,
    reducers: {
        resetStatusAndMsg: (state) => {
            state.msg = ''
            state.status = 'idle'
        },
        handleDetailModalState: (state, action) => {
            state.detailModalState = action.payload
        },
        handleDetailModalData: (state, action) => {
            state.detailModalData = action.payload
        },
        resetStatusAndMsgJehona(state){
            state.jehonaApartmentStatus = 'idle';
            state.jehonaApartmentMsg = '';
        },
        resetStatusAndMsgTophane(state){
            state.tophaneApartmentStatus = 'idle';
            state.tophaneApartmentMsg = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getApartmentsSvgDataAll.pending , (state, action) => {
            state.status = 'getAll_pending';
        })
        .addCase(getApartmentsSvgDataAll.fulfilled , (state, action) => {
            state.status = 'getAll_success';
            state.svgData = action.payload;
        })
        .addCase(getApartmentsSvgDataAll.rejected , (state, action) => {
            state.msg = action.payload;
            state.status = 'getAll_rejected';
        })
        .addCase(updateApartment.pending , (state, action) => {
            state.jehonaApartmentStatus = 'updateApartment_pending'
        })
        .addCase(updateApartment.fulfilled , (state, action) => {
            state.jehonaApartmentStatus = 'updateApartment_success'
        })
        .addCase(updateApartment.rejected , (state, action) => {
            state.jehonaApartmentStatus = 'updateApartment_rejected'
            state.jehonaApartmentMsg = action.payload;
        })
        .addCase(getObjectSvgDataAll.pending , (state, action) => {
            state.statusObjects = 'objects_svg_getAll_pending'
        })
        .addCase(getObjectSvgDataAll.fulfilled , (state, action) => {
            state.statusObjects = 'objects_svg_getAll_success'
            state.objectSvgData = action.payload;
        })
        .addCase(getObjectSvgDataAll.rejected , (state, action) => {
            state.statusObjects = 'objects_svg_getAll_rejected'
        })
        .addCase(getApartmentsAllData.pending , (state, action) => {
            state.statusApartment = 'apartments_getonlydata_pending';
        })
        .addCase(getApartmentsAllData.fulfilled , (state, action) => {
            state.statusApartment = 'apartments_getonlydata_success';
            state.apartmentSvgData = action.payload
        })
        .addCase(getApartmentsAllData.rejected , (state, action) => {
            state.statusApartment = 'apartments_getonlydata_rejected';
        })
        .addCase(getJehonaApartmentsByBuilding.pending , (state, action) => {
            state.jehonaApartmentStatus = 'apartments_getdata_pending'
        })
        .addCase(getJehonaApartmentsByBuilding.fulfilled , (state, action) => {
            state.jehonaApartmentData = action.payload;
            state.jehonaApartmentStatus = 'apartments_getdata_success';
        })
        .addCase(getJehonaApartmentsByBuilding.rejected , (state, action) => {
            state.jehonaApartmentStatus = 'apartments_getdata_rejected'
        })
        .addCase(getAllApartmentsByFloorAndBuilding.pending , (state, action) => {
            state.tophaneApartmentStatus = 'pending';
        })
        .addCase(getAllApartmentsByFloorAndBuilding.fulfilled , (state, action) => {
            state.tophaneApartmentStatus = 'pending';
            state.tophaneApartmentData = action.payload
        })
        .addCase(getAllApartmentsByFloorAndBuilding.rejected , (state, action) => {
            state.tophaneApartmentStatus = 'pending';
            state.tophaneApartmentMsg = action.payload
        })
    }
});
export const getApartmentSvgData = (state) => state.ObjectsSlice.apartmentSvgData;
export const getObjectSvgData = (state) => state.ObjectsSlice.objectSvgData;
export const getObjectsStatus = (state) => state.ObjectsSlice.statusObjects;
export const getObjectsDetailModalState = (state) => state.ObjectsSlice.detailModalState;
export const getObjectsDetailModalData = (state) => state.ObjectsSlice.detailModalData;
export const getJehonaApartmentData = (state) => state.ObjectsSlice.jehonaApartmentData;
export const getJehonaApartmentStatus = (state) => state.ObjectsSlice.jehonaApartmentStatus;
export const getTophaneApartmentData = (state) => state.ObjectsSlice.tophaneApartmentData;
export const getTophaneApartmentStatus = (state) => state.ObjectsSlice.tophaneApartmentStatus;
export const { 
    resetStatusAndMsg,
    handleDetailModalState,
    handleDetailModalData,
    resetStatusAndMsgJehona,
    resetStatusAndMsgTophane,
} = ObjectsSlice.actions;
export default ObjectsSlice.reducer;