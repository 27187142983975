import React from 'react';
import Navbar from '../components/navbar/Navbar';
import { Card } from '../components';
import WidthWrapper from '../components/common/WidthWrapper';
import { Box, Grid, Typography } from '@mui/material';

const Projects = () => {
  return (
    <div style={{width: '100%', height: '100%'}}>
        <Navbar />
        <div style={{marginTop: '100px', marginLeft: '10px', display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '90px'}}>
            <WidthWrapper>
                <Box width={'100%'} my={2} mb={4}>
                    <Typography textAlign={'center'} variant='h4'>Projektet E DP Partners</Typography>
                </Box>
            </WidthWrapper>
            <WidthWrapper>
                <Grid container spacing={2} mb={5}>
                    <Grid item xs={12} md={4}>
                        <Card locationUrl={'Jehona'} imageUrl={'https://projektet-dp-partners.roitiv.com/objektetImg/jehonaGeneral.jpg'} description={'Jehona'}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card locationUrl={'Lagja e kalter'} imageUrl={'https://projektet-dp-partners.roitiv.com/objektetImg/lagjaKalterGeneral.jpg'} description={'Lagja e kalter'}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card locationUrl={'Tophane'} imageUrl={'https://projektet-dp-partners.roitiv.com/objektetImg/tophaneGeneral.jpg'} description={'Tophane'}/>
                    </Grid>
                </Grid>
            </WidthWrapper>
        </div>

    </div>
  )
}

export default Projects