import { useState } from "react";
import { mainUrl } from "../../../../utils/consts";
import ContextMenu from "../../../contextMenu/ContextMenu";

const Floor1 = ({handleContextMenu, apartments}) => {
  
  return (
    
    <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="-80 190 1400 650.3"
    xmlSpace="preserve"
    >
    <image
      width="1276"
      height="935"
      xlinkHref={`${mainUrl}/objektetImg/kati.png`}
    ></image>
    
    {
      apartments?.map((apartment) => {
        
        return  <>
        <polygon
      onContextMenu={(e) => handleContextMenu(apartment, e)}
      className={apartment?.object === 'a5' ? (apartment?.object === 'a5' && apartment?.isSold ? "st2" : 'st1') : 'blank'}
      id="a5"
      points="265.4,221.1 425.1,221 425.1,224.6 460.4,224.6 460.4,306.4 525.5,306.4 605.9,306.4 605.9,274.6 
            665.1,274.6 665.1,344.8 596.9,344.8 596.9,425.5 604.6,425.7 604.6,447 336.5,447 336.5,453.5 328.8,453.5 328.8,450.6 
            264.5,450.6 265.1,234.1 265.1,221.2 "
    />
    <polygon
      onContextMenu={(e) => handleContextMenu(apartment, e)}
      className={apartment?.object === 'a1' ? (apartment?.object === 'a1' && apartment?.isSold ? "st2" : 'st1') : 'blank'}
      id="a1"
      points="936.1,281.8 1196.7,281.3 1196.6,285.9 1250,285.4 1250,322.5 1254.1,322.5 1254.1,745.1 1199.9,744.9 
            1199.9,783.9 1025.8,783.9 1025.9,745.4 938.3,745.1 939.7,310 935.6,310 "
    />
    <polygon
      onContextMenu={(e) => handleContextMenu(apartment, e)}
      className={apartment?.object === 'a2' ? (apartment?.object === 'a2' && apartment?.isSold ? "st2" : 'st1') : 'blank'}
      id="a2"
            points="938.8,503.6 556.1,503.4 556.1,745.1 661.4,745.1 661.4,783.9 812.3,783.9 812.4,745.1 938.3,745.1 "
    />
    <polygon
      onContextMenu={(e) => handleContextMenu(apartment, e)}
      className={apartment?.object === 'a3' ? (apartment?.object === 'a3' && apartment?.isSold ? "st2" : 'st1') : 'blank'}
      id="a3"
      points="556.1,503.4 337,503.3 273.1,503.3 273.1,745.4 323,745.4 323,783.9 430.1,783.9 430.1,745.4 
            556.1,745.1 "
    />
    <polygon
      onContextMenu={(e) => handleContextMenu(apartment, e)}
      className={apartment?.object === 'a4' ? (apartment?.object === 'a4' && apartment?.isSold ? "st2" : 'st1') : 'blank'}
      id="a4"
      points="21.9,220.5 270.8,220.9 271.5,444.7 337.3,444.7 337,503.3 273.1,503.3 273.1,745.4 85.2,745.1 
            85.1,741.6 26,741.6 26,660.5 21.9,660.8 "
    />
        <g id="a3" className={apartment?.object === 'a3' && apartment?.isSold ? "circle-lock-show" : 'circle-lock'}>
          <circle className="circle" cx="417.3" cy="623.8" r="25" />
          <path
            className="lock"
            d="M429.1,621.3l-3.1-0.4c0-2.1,0.1-5.4,0-5.9c-0.3-4-3.7-7.4-7.8-7.7c-0.3,0-0.5,0-0.8,0c-2.3,0-4.2,0.7-5.9,2.1
                    c-1.7,1.4-2.7,3.5-2.9,5.5c0,0.5,0,4.1,0,6.2l-3.2,0.4l0.1,17.2l11.9,1.5l11.8-1.8L429.1,621.3z M419.3,634.5h-3.9l0.6-4.8
                    c-0.6-0.4-0.9-1.1-0.9-1.8c0-1.2,1-2.2,2.3-2.2c1.3,0,2.3,1,2.3,2.2c0,0.7-0.4,1.4-0.9,1.8L419.3,634.5z M417.2,619.8l-5,0.7
                    c0-2.6,0.1-6.3,0.8-7.3c0.9-1.5,2.4-2.4,4.2-2.4c0.1,0,0.1,0,0.2,0c1.8,0,3.3,0.9,4.2,2.4c0.6,1,0.8,4.6,0.8,7.3L417.2,619.8z"
          />
        </g>
        <g id="a5" className={apartment?.object === 'a1' && apartment?.isSold ? "circle-lock-show" : 'circle-lock'}>
          <circle className="circle" cx="442.3" cy="362.3" r="25" />
          <path
            className="lock"
            d="M454.1,359.8l-3.1-0.4c0-2.1,0.1-5.4,0-5.9c-0.3-4-3.7-7.4-7.8-7.7c-0.3,0-0.5,0-0.8,0c-2.3,0-4.2,0.7-5.9,2.1
                    c-1.7,1.4-2.7,3.5-2.9,5.5c0,0.5,0,4.1,0,6.2l-3.2,0.4l0.1,17.2l11.9,1.5l11.8-1.8L454.1,359.8z M444.3,373h-3.9l0.6-4.8
                    c-0.6-0.4-0.9-1.1-0.9-1.8c0-1.2,1-2.2,2.3-2.2c1.3,0,2.3,1,2.3,2.2c0,0.7-0.4,1.4-0.9,1.8L444.3,373z M442.3,358.3l-5,0.7
                    c0-2.6,0.1-6.3,0.8-7.3c0.9-1.5,2.4-2.4,4.2-2.4c0.1,0,0.1,0,0.2,0c1.8,0,3.3,0.9,4.2,2.4c0.6,1,0.8,4.6,0.8,7.3L442.3,358.3z"
          />
        </g>
        <g id="a4" className={apartment?.object === 'a4' && apartment?.isSold ? "circle-lock-show" : 'circle-lock'}>
          <circle className="circle" cx="140" cy="471.8" r="25" />
          <path
            className="lock"
            d="M151.8,469.3l-3.1-0.4c0-2.1,0.1-5.4,0-5.9c-0.3-4-3.7-7.4-7.8-7.7c-0.3,0-0.5,0-0.8,0c-2.3,0-4.2,0.7-5.9,2.1
                    c-1.7,1.4-2.7,3.5-2.9,5.5c0,0.5,0,4.1,0,6.2l-3.2,0.4l0.1,17.2l11.9,1.5l11.8-1.8L151.8,469.3z M142.1,482.5h-3.9l0.6-4.8
                    c-0.6-0.4-0.9-1.1-0.9-1.8c0-1.2,1-2.2,2.3-2.2c1.3,0,2.3,1,2.3,2.2c0,0.7-0.4,1.4-0.9,1.8L142.1,482.5z M140,467.9l-5,0.7
                    c0-2.6,0.1-6.3,0.8-7.3c0.9-1.5,2.4-2.4,4.2-2.4c0.1,0,0.1,0,0.2,0c1.8,0,3.3,0.9,4.2,2.4c0.6,1,0.8,4.6,0.8,7.3L140,467.9z"
          />
        </g>
        <g id="a2" className={apartment?.object === 'a2' && apartment?.isSold ? "circle-lock-show" : 'circle-lock'}>
          <circle className="circle" cx="747.4" cy="623.8" r="25" />
          <path
            className="lock"
            d="M759.2,621.3l-3.1-0.4c0-2.1,0.1-5.4,0-5.9c-0.3-4-3.7-7.4-7.8-7.7c-0.3,0-0.5,0-0.8,0c-2.3,0-4.2,0.7-5.9,2.1
                    c-1.7,1.4-2.7,3.5-2.9,5.5c0,0.5,0,4.1,0,6.2l-3.2,0.4l0.1,17.2l11.9,1.5l11.8-1.8L759.2,621.3z M749.5,634.5h-3.9l0.6-4.8
                    c-0.6-0.4-0.9-1.1-0.9-1.8c0-1.2,1-2.2,2.3-2.2c1.3,0,2.3,1,2.3,2.2c0,0.7-0.4,1.4-0.9,1.8L749.5,634.5z M747.4,619.8l-5,0.7
                    c0-2.6,0.1-6.3,0.8-7.3c0.9-1.5,2.4-2.4,4.2-2.4c0.1,0,0.1,0,0.2,0c1.8,0,3.3,0.9,4.2,2.4c0.6,1,0.8,4.6,0.8,7.3L747.4,619.8z"
          />
        </g>
        <g id="a1" className={apartment?.object === 'a1' && apartment?.isSold ? "circle-lock-show" : 'circle-lock'}>
          <circle className="circle" cx="1101.1" cy="496.8" r="25" />
          <path
            className="lock"
            d="M1112.9,494.3l-3.1-0.4c0-2.1,0.1-5.4,0-5.9c-0.3-4-3.7-7.4-7.8-7.7c-0.3,0-0.5,0-0.8,0
                    c-2.3,0-4.2,0.7-5.9,2.1c-1.7,1.4-2.7,3.5-2.9,5.5c0,0.5,0,4.1,0,6.2l-3.2,0.4l0.1,17.2l11.9,1.5l11.8-1.8L1112.9,494.3z
                    M1103.1,507.6h-3.9l0.6-4.8c-0.6-0.4-0.9-1.1-0.9-1.8c0-1.2,1-2.2,2.3-2.2c1.3,0,2.3,1,2.3,2.2c0,0.7-0.4,1.4-0.9,1.8
                    L1103.1,507.6z M1101,492.9l-5,0.7c0-2.6,0.1-6.3,0.8-7.3c0.9-1.5,2.4-2.4,4.2-2.4c0.1,0,0.1,0,0.2,0c1.8,0,3.3,0.9,4.2,2.4
                    c0.6,1,0.8,4.6,0.8,7.3L1101,492.9z"
          />
        </g>
        <g className={apartment?.object === 'a4' && !apartment?.isSold ? "circle-text-show" : 'circle-text'}>
          <circle className="circle" cx="140" cy="471.5" r="25" />
          <text transform="matrix(1 0 0 1 126.3389 481.291)" className="text">
            B4
          </text>
        </g>
        <g className={apartment?.object === 'a5' && !apartment?.isSold ? "circle-text-show" : 'circle-text'}>
          <circle className="circle" cx="442.3" cy="361.9" r="25" />
          <text transform="matrix(1 0 0 1 428.6367 370.9931)" className="text">
            B5
          </text>
        </g>
        <g className={apartment?.object === 'a3' && !apartment?.isSold ? "circle-text-show" : 'circle-text'}>
          <circle className="circle" cx="417.3" cy="623.4" r="25" />
          <text transform="matrix(1 0 0 1 403.1048 632.4395)" className="text">
            B3
          </text>
        </g>
        <g className={apartment?.object === 'a2' && !apartment?.isSold ? "circle-text-show" : 'circle-text'}>
          <circle className="circle" cx="747.4" cy="623.4" r="25" />
          <text transform="matrix(1 0 0 1 733.2325 632.4395)" className="text">
            B2
          </text>
        </g>
        <g className={apartment?.object === 'a1' && !apartment?.isSold ? "circle-text-show" : 'circle-text'}>
          <circle className="circle" cx="1101.1" cy="496.5" r="25" />
          <text transform="matrix(1 0 0 1 1087.8705 505.4608)" className="text">
            B1
          </text>
        </g>
        </>
      }
    
       
        
          )
    }
  </svg>
    )
}

export default Floor1