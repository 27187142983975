import { Box, Divider, IconButton, Typography } from '@mui/material'
import React from 'react'
import './style.css'
import { useNavigate } from 'react-router-dom'
import { Bookmark } from '@mui/icons-material'

const PlanCard = ( { imageUrl, description, locationUrl, onClick, data } ) => {
    const navigate = useNavigate()
  return (
    <React.Fragment>
      <Box
        onClick={() => {
          if (locationUrl) {
            navigate(`/${description.toLowerCase()}`);
          } else {
            if (onClick) {
              onClick();
            }
          }
        }}
        sx={{
          width: {
            xs: 0,
            md: "100%",
          },
          height: {
            xs: 0,
            md: 150,
          },
          display: {
            xs: "none",
            md: "flex",
          },
          border: "1px solid grey",
          p: 1,
          cursor: "pointer",
          backgroundColor: 'rgba(82, 117, 64, 0.12)',
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            width: 145,
            height: 145,
            ml: 1,
          }}
        >
          <img className="cardImage" width={"100%"} src={imageUrl} />
        </Box>
        <Box
          sx={{
            p: 1,
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Divider
              sx={{
                ml: {
                  md: 5,
                },
              }}
              orientation="vertical"
            />
            <Box>
              <Typography fontFamily={"Montserrat"} fontSize={'1.1rem'}>Sipërfaqja</Typography>
              <Typography fontFamily={"Montserrat"} fontSize={"3rem"}>
                {data.square}m<sup>2</sup>
              </Typography>
            </Box>
            <Box>
              <Typography fontFamily={"Montserrat"} fontSize={'1.1rem'}>Kati</Typography>
              <Typography fontFamily={"Montserrat"} fontSize={"3rem"}>
                {data.floorNumber}
              </Typography>
            </Box>
            <Box>
              <Typography fontFamily={"Montserrat"} fontSize={'1.1rem'}>Lokacioni</Typography>
              <Typography fontFamily={"Montserrat"} fontSize={"3rem"}>
                {data.neighborhood}
              </Typography>
            </Box>
            <IconButton onClick={(e) => { e.stopPropagation(); alert('AA')}} sx={{ border: "1px solid grey" }} size="large">
              <Bookmark fontSize="large" />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {/* mobile section */}

      <Box
        onClick={() => {
          if (locationUrl) {
            navigate(`/${description.toLowerCase()}`);
          } else {
            if (onClick) {
              onClick();
            }
          }
        }}
        sx={{
          width: {
            xs: "100%",
            md: 0,
          },
          height: {
            xs: "auto",
            md: 0,
          },
          display: {
            xs: "flex",
            md: "none",
          },
          border: "1px solid grey",
          flexDirection: "column",
          cursor: "pointer",
          backgroundColor: 'rgba(82, 117, 64, 0.12)',
          borderRadius: 3,
          ":hover": {
            boxShadow: "1px 2px 2px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            m: 2,
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Kati {data.floorNumber}</Typography>
          <IconButton>
            <Bookmark />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img className="cardImage" width={"45%"} src={imageUrl} />
        </Box>
        <Divider
          sx={{
            my: 2,
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            m: 2,
          }}
        >
          <Typography variant="h6">Siperfaqja {data.square}</Typography>
          <Typography variant="h6">Banesa {data.apartmentName}</Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default PlanCard