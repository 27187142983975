import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, BASE_URL_JEHONA } from "../../utils/consts";
import { tokenConfig, tokenConfigContentMultipart } from "../auth/AuthSlice";

const urlObjects = `${BASE_URL}/api/building`;
const urlApartment = `${BASE_URL_JEHONA}/api/apartment`;

export const getObjectSvgDataAll = createAsyncThunk(
    'ObjectsSlice/objectsgetall',
    async(page, { rejectWithValue }) => {
        try {
           const res = await axios.get(`${urlObjects}/getall?page=${page}`);
           return res.data; 
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getApartmentsSvgDataAll = createAsyncThunk(
    'ObjectsSlice/getall',
    async(a, { rejectWithValue }) => {
        try {
           const res = await axios.get(`${urlApartment}/get`);
           return res.data; 
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getApartmentsSvgDataAllById = createAsyncThunk(
    'ObjectsSlice/getall',
    async(a, { rejectWithValue }) => {
        try {
           const res = await axios.get(`${urlApartment}/get`);
           return res.data; 
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getApartmentsAllData = createAsyncThunk(
    'ObjectSlice/getAllApartmentsData',
    async(a, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${urlObjects}/getdata`);
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const updateIsSold = createAsyncThunk(
    'ObjectsSlice/updateIsSold'
);

export const getJehonaApartmentsByBuilding = createAsyncThunk(
    'ObjectSlice/getJehonaApartmentsByBuilding',
    async(a, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${urlApartment}/data/getbyid?id=${a}`);
            return res.data;
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const getJehonaApartmentByBuilding = createAsyncThunk(
    'ObjectSlice/getJehonaApartmentsByBuilding',
    async(a, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${urlApartment}/dataall/getbyid?id=${a}`);
            return res.data;
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const getApartmentById = createAsyncThunk();

export const getAllApartmentsById = createAsyncThunk();

export const getAllApartmentsByFloorAndBuilding = createAsyncThunk(
    'ApartmentSlice/getallbybuilding',
    async(id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${urlApartment}/getallbyfloor?id=${id}`, tokenConfig())
            return res.data;
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
);


export const updateApartment = createAsyncThunk(
    'ObjectsSlice/updateApartment',
    async({id, data}, { rejectWithValue }) => {
        try {
            const res = await axios.post(`${urlApartment}/update?id=${id}`, data, tokenConfig());
            return res.data;
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)