import { useMediaQuery, useTheme } from "@mui/material";
import ApartmentDetailsModalRegularDev from "./components/ApartmentDetailsModalRegularDev";
import ApartmentDetailsModalSmallDev from "./components/ApartmentDetailsModalSmallDev";


const ApartmentDetailsModal = () => {
  const theme = useTheme()
  const isSmallDev = useMediaQuery(theme.breakpoints.down('md'))
  
  if(isSmallDev) return <ApartmentDetailsModalSmallDev />

  return <ApartmentDetailsModalRegularDev />;
}

export default ApartmentDetailsModal