import { Box, Typography } from '@mui/material'
import React from 'react'
import { colors, projectFilter } from '../../utils/theme'
import { FilterAlt, KeyboardArrowDown, Restore } from '@mui/icons-material'
import FilterCard from './components/FilterCard'
import { useState } from 'react'
import CustomLocationFilter from './components/projectFilter/CustomLocationFilter'
import CustomPlanRoomFilter from './components/projectFilter/CustomPlanRoomFilter'
import CustomSquareFilter from './components/projectFilter/CustomSquareFilter'
import CustomStatusFilter from './components/projectFilter/CustomStatusFilter'
import CustomSquareFieldFilter from './components/CustomSquareFieldFilter'
import { useDispatch } from 'react-redux'

const locationComponent = <CustomLocationFilter />
const squareComponent = <CustomPlanRoomFilter />
const roomComponent = <CustomSquareFilter />
const statusComponent = <CustomStatusFilter />

const ProjectFilter = () => {
    const dispatch = useDispatch();
    const [popover, setPopover] = useState({
        anchorEl: null,
        child: null,
    })
    const handleFilter = (e, b) => {
        setPopover({
          anchorEl: e.target,
          child: b,
        });
    }
  return (
    <Box sx={{ ...projectFilter.container }}>
      <Box
        onClick={(e) => handleFilter(e, locationComponent)}
        sx={{ ...projectFilter.cardContainer, cursor: "pointer" }}
      >
        Lokacioni
        <KeyboardArrowDown />
      </Box>
      <Box
        sx={{
          ...projectFilter.cardContainer,
          gap: 5,
          flex: {
            xs: 12,
            md: 3,
          },
        }}
      >
        <CustomSquareFieldFilter />
        <KeyboardArrowDown
          sx={{ cursor: "pointer" }}
          onClick={(e) => handleFilter(e, roomComponent)}
        />
      </Box>
      <Box
        onClick={(e) => handleFilter(e, squareComponent)}
        sx={{ ...projectFilter.cardContainer, cursor: "pointer" }}
      >
        Tipi
        <KeyboardArrowDown />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: {
            xs: 12,
            md: 2,
            lg: 2,
          }
        }}
      >
        <Box
          sx={{
            width: "100%",
            border: "1px solid #527540",
            borderRadius: '50px',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: '#527540',
            bgcolor: 'white',
            py: 1,
            cursor: 'pointer'
          }}
        >
          <Box onClick={() => {
          }} display={"flex"} gap={1} alignItems={'center'}>
            <Restore />
            <Typography fontFamily={'Inter'} fontSize={'20px'}>Reseto</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: {
            xs: 12,
            md: 2,
            lg: 2,
          }
        }}
      >
        <Box
          sx={{
            width: "100%",
            border: "1px solid #527540",
            borderRadius: '50px',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: '#74A284',
            py: 1,
            cursor: 'pointer'
          }}
        >
          <Box onClick={() => {
          }} display={"flex"} gap={1} alignItems={'center'}>
            <FilterAlt />
            <Typography fontFamily={'Inter'} fontSize={'20px'}>Filtro</Typography>
          </Box>
        </Box>
      </Box>
      <FilterCard controls={{ popover, setPopover }} children={popover.child} />
    </Box>
  );
}

export default ProjectFilter