export const mainUrl = 'https://projektet-dp-partners.roitiv.com/';
export const BASE_URL = 'https://srv412786.hstgr.cloud:8845'
export const BASE_URL_JEHONA = 'https://srv412786.hstgr.cloud:8845'
// export const BASE_URL = 'http://localhost:8845';
// export const BASE_URL_JEHONA = 'http://localhost:8845';

export const EURO_SYMBOL = '€';
export const homepage = `https://www.partners-dp.com/`
export const imagePath = `https://projektet-dp-partners.roitiv.com/objektetImg/`
export const pdfPath = `https://www.partners-dp.com/apartmentImg/pdf/`
export const navImgPath = `https://www.partners-dp.com/apartmentImg/pdf/`

export const leftArrowButtonSvg = <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" xmlSpace="preserve">
<g><g><g><path d="M130.5,15.6C130,15.9,102.8,38.5,70,65.9c-55.7,46.5-59.6,49.8-59.9,51.4c-0.2,1.3-0.1,2.1,0.6,3.2c0.6,0.9,22.5,19,58.7,48.2c31.8,25.6,58.7,47.4,59.8,48.2c2.9,2.2,5.6,2,7.6-0.5c0.7-0.9,0.8-3,0.8-27.4v-26.4h7.3c23,0,43.1,4.8,58.6,14c16.7,9.9,27.3,24.9,31.2,43.9c0.7,3.4,1.6,12.8,1.7,16.3c0,4.4,6.6,5.9,8.9,1.9c0.7-1.2,0.8-4.8,0.8-41.4c0-24.9-0.2-42-0.5-45.1c-2.7-27.4-16.3-49.2-39.8-63.2c-11.2-6.6-26.3-11.6-42.2-13.8c-2.9-0.4-9.9-0.8-15.5-1l-10.3-0.3V45.9V17.7l-1.3-1.3C134.8,14.9,132.3,14.6,130.5,15.6z"/></g></g></g>
</svg>;

export const rightArrowButtonSvg = <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" xmlSpace="preserve">
<g><g><g><path d="M119.7,16.4l-1.3,1.3v28.1v28.1l-10.2,0.3c-5.7,0.1-12.6,0.6-15.5,1c-16,2.2-31.1,7.1-42.2,13.8c-23.5,14.1-37.2,35.7-39.8,63.2c-0.3,3.1-0.5,20.2-0.5,45.1c0,36.6,0,40.2,0.8,41.4c1.5,2.6,5.3,3.1,7.5,1c1.2-1.2,1.3-1.6,1.8-8.6c1.4-18.9,7.2-32.3,19-44.1c15.8-15.8,41.1-24.4,72.1-24.4h7.2V189c0,24.3,0.1,26.5,0.8,27.4c1.7,2.1,4.6,2.6,6.5,1.2c0.6-0.5,23.7-19,51.4-41.4c27.7-22.3,54.2-43.8,59-47.6c5.2-4.1,9-7.7,9.4-8.4c0.5-1,0.6-1.8,0.2-3.1c-0.4-1.6-5.9-6.3-59.4-51C153.9,39,126.7,16.4,126,15.9C123.8,14.6,121.4,14.8,119.7,16.4z"/></g></g></g>
</svg>

export const bedIconSvg = <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M496,344h-8V280a32.042,32.042,0,0,0-32-32V112a32.042,32.042,0,0,0-32-32H88a32.042,32.042,0,0,0-32,32V248a32.042,32.042,0,0,0-32,32v64H16a8,8,0,0,0-8,8v32a8,8,0,0,0,8,8h8v32a8,8,0,0,0,8,8H56a7.99,7.99,0,0,0,7.84-6.43L70.56,392H441.44l6.72,33.57A7.99,7.99,0,0,0,456,432h24a8,8,0,0,0,8-8V392h8a8,8,0,0,0,8-8V352A8,8,0,0,0,496,344ZM72,112A16.021,16.021,0,0,1,88,96H424a16.021,16.021,0,0,1,16,16V248H424V216a32.042,32.042,0,0,0-32-32H296a32.042,32.042,0,0,0-32,32v32H248V216a32.042,32.042,0,0,0-32-32H120a32.042,32.042,0,0,0-32,32v32H72ZM408,216v32H280V216a16.021,16.021,0,0,1,16-16h96A16.021,16.021,0,0,1,408,216Zm-176,0v32H104V216a16.021,16.021,0,0,1,16-16h96A16.021,16.021,0,0,1,232,216ZM40,280a16.021,16.021,0,0,1,16-16H456a16.021,16.021,0,0,1,16,16v64H40Zm9.44,136H40V392H54.24ZM472,416h-9.44l-4.8-24H472Zm16-40H24V360H488Z"/></svg>

export const buildingIconSvg = <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 480 480" xmlSpace="preserve">
<g>
  <g>
      <g>
          <path d="M472,464h-48V200c0-4.418-3.582-8-8-8H304V8c0-4.418-3.582-8-8-8H88c-4.418,0-8,3.582-8,8v456H8c-4.418,0-8,3.582-8,8
           s3.582,8,8,8h464c4.418,0,8-3.582,8-8S476.418,464,472,464z M168,464h-32v-72c0-4.418,3.582-8,8-8h16c4.418,0,8,3.582,8,8V464z
            M200,200v264h-16v-72c0-13.255-10.745-24-24-24h-16c-13.255,0-24,10.745-24,24v72H96V16h192v176h-80
           C203.582,192,200,195.582,200,200z M408,464H216V208h192V464z"/>
          <path d="M256,112h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C264,115.582,260.418,112,256,112z
            M248,160h-32v-32h32V160z"/>
          <path d="M256,32h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8V40C264,35.582,260.418,32,256,32z
            M248,80h-32V48h32V80z"/>
          <path d="M176,112h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C184,115.582,180.418,112,176,112z
            M168,160h-32v-32h32V160z"/>
          <path d="M176,32h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8V40C184,35.582,180.418,32,176,32z
            M168,80h-32V48h32V80z"/>
          <path d="M176,192h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C184,195.582,180.418,192,176,192z
            M168,240h-32v-32h32V240z"/>
          <path d="M176,272h-48c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-48C184,275.582,180.418,272,176,272z
            M168,320h-32v-32h32V320z"/>
          <path d="M328,368h48c4.418,0,8-3.582,8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418,0-8,3.582-8,8v48C320,364.418,323.582,368,328,368z
            M336,320h32v32h-32V320z"/>
          <path d="M328,288h48c4.418,0,8-3.582,8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418,0-8,3.582-8,8v48C320,284.418,323.582,288,328,288z
            M336,240h32v32h-32V240z"/>
          <path d="M328,448h48c4.418,0,8-3.582,8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418,0-8,3.582-8,8v48C320,444.418,323.582,448,328,448z
            M336,400h32v32h-32V400z"/>
          <path d="M248,368h48c4.418,0,8-3.582,8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418,0-8,3.582-8,8v48C240,364.418,243.582,368,248,368z
            M256,320h32v32h-32V320z"/>
          <path d="M248,288h48c4.418,0,8-3.582,8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418,0-8,3.582-8,8v48C240,284.418,243.582,288,248,288z
            M256,240h32v32h-32V240z"/>
          <path d="M248,448h48c4.418,0,8-3.582,8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418,0-8,3.582-8,8v48C240,444.418,243.582,448,248,448z
            M256,400h32v32h-32V400z"/>
      </g>
  </g>
</g>
</svg>;