import React from 'react'

const WidthWrapper = ( { children } ) => {
  return (
    <div style={{ width: '80%'}}>
        {children}
    </div>
  )
}

export default WidthWrapper