import React, { useRef, useState } from 'react'
import { Box } from '@mui/material'
import GenericPopOver from '../../popover/jehona/GenericPopOver'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getApartmentSvgData } from '../../../features/objects/ObjectsSlice'
import { getApartmentsAllData } from '../../../features/objects/ObjectsApi'
import { getFilterData, getFilterMobileModalState, getFilterState } from '../../../features/filter/FilterSlice'
import { useNavigate } from 'react-router-dom'

const JehonaBuilding = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const apartmentData = useSelector(getApartmentSvgData);
    const [apartments, setApartments] = useState(null);
    const filter = useSelector(getFilterData)
    const filterState = useSelector(getFilterState);
    const mobileFilterState = useSelector(getFilterMobileModalState);
    const [popover, setPopover] = useState({
        anchorEl1: null,
        anchorEl2: null,
        anchorEl3: null,
        anchorEl4: null,
        anchorEl5: null,
        anchorEl6: null,
        anchorEl7: null,
        anchorEl8: null,
        item: null,
    })
    const object = {
     object1: useRef(null),
     object2: useRef(null),
     object3: useRef(null),
     object4: useRef(null),
     object5: useRef(null),
     object6: useRef(null),
     object7: useRef(null),
     object8: useRef(null),
    }
    useEffect(() => {
      dispatch(getApartmentsAllData())
    }, [])
    useEffect(() => {
      if((filterState === false && mobileFilterState === true) || filterState === false) {
        setPopover({
          anchorEla: null,
          anchorElb: null,
          anchorElc: null,
          anchorEld: null,
          anchorEle: null,
          anchorElf: null,
          anchorElg: null,
          anchorElh: null,
      })
      }
      else {
        setPopover({
          anchorEla: object.object1,
          anchorElb: object.object2,
          anchorElc: object.object3,
          anchorEld: object.object4,
          anchorEle: object.object5,
          anchorElf: object.object6,
          anchorElg: object.object7,
          anchorElh: object.object8,
      })
      }
    }, [filterState, mobileFilterState])
    useEffect(() => {
        object.object1.current = document.getElementById('object1')
        object.object2.current = document.getElementById('object2')
        object.object3.current = document.getElementById('object3')
        object.object4.current = document.getElementById('object4')
        object.object5.current = document.getElementById('object5')
        object.object6.current = document.getElementById('object6')
        object.object7.current = document.getElementById('object7')
        object.object8.current = document.getElementById('object8')
    }, [])
    useEffect(() => {
      const groupedData = apartmentData?.reduce((acc, cur) => {
        if (!acc[cur.buildingNr]) {
          acc[cur.buildingNr] = {
            count: 0,
          };
        }
        if (cur.square <= filter.square.endVal && cur.square >=  filter.square.startVal &&
          cur.floorNumber <= filter.floor.endVal && cur.floorNumber >= filter.floor.startVal && 
          (filter.rooms.includes(cur.rooms) || filter.rooms.includes('all')) &&
          cur.isSold === false) {
          acc[cur.buildingNr].count += 1;
        }
        return acc;
      }, {});
      if(groupedData){
        setApartments(prevState => ({ ...prevState, ...groupedData })); // Ensure prevState is updated properly
        
      }
      
  }, [apartmentData, filter])
    useEffect(() => {
      if(filterState !== false) {
        setPopover({
          anchorEla: object.object1,
          anchorElb: object.object2,
          anchorElc: object.object3,
          anchorEld: object.object4,
          anchorEle: object.object5,
          anchorElf: object.object6,
          anchorElg: object.object7,
          anchorElh: object.object8,
      })
      }
    }, [object.object1])
  return (
    <Box
      sx={{
        mt: -15,
        overflowX: {
            xs: 'auto',
            md: 'hidden'
        },
        overflowY: 'hidden',
        width: {
            xs: '350%',
            md: '100%'
        },
      }}
    >
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 1920 1080" xmlSpace="preserve">
      <image width="1920" height="1080" xlinkHref="https://projektet-dp-partners.roitiv.com/objektetImg/jehonaGeneral.jpg" >
      </image>
      <path className="st0" d="M68.48,449.4"/>
      <polygon onClick={() => navigate('/jehona/building/a')} name='a' id='object1' className="st1" points="324.04,481.04 324.04,848.96 512.35,826.8 583.56,818.89 593.85,789.62 593.85,471.55 567.74,471.55 
        564.57,443.86 324.04,443.86 "/>
      <polygon  onClick={() => navigate('/jehona/building/b')} name='b' id='object2' className="st1" points="854.15,604.47 854.15,469.97 829.63,462.85 652.4,466.8 648.44,469.18 648.44,489.75 593.85,491.33 
        593.85,789.62 732.31,776.16 733.1,663.81 767.91,632.16 796.4,632.96 796.4,626.23 821.71,606.05 854.15,605.66 "/>
      <polygon onClick={() => navigate('/jehona/building/h')} id='object8' name='h' className="st1" points="735.47,898.8 789.27,898.8 789.27,913.04 1060.66,917.79 1060.66,626.23 1053.54,606.85 955.43,606.85 
        955.43,600.52 892.13,600.52 887.38,606.05 821.71,606.05 796.4,626.23 796.4,632.96 767.91,632.16 733.1,663.81 "/>
      <polygon onClick={() => navigate('/jehona/building/g')} id='object7' name='g' className="st1" points="1336.79,923.33 1336.79,909.09 1391.38,909.09 1391.38,670.14 1336.79,636.12 1324.92,636.12 
        1324.92,630.58 1290.11,608.43 1219.69,608.43 1210.99,600.52 1145.32,600.52 1149.27,608.43 1053.54,606.85 1060.66,626.23 
        1060.66,917.79 "/>
      <polygon onClick={() => navigate('/jehona/building/c')} id='object3' name='c' className="st1" points="1077.27,466.8 1077.27,607.24 955.43,606.85 955.43,600.52 892.13,600.52 887.38,606.05 854.15,604.47 
        854.15,469.97 829.63,462.85 1032.97,462.85 "/>
      <path onClick={() => navigate('/jehona/building/f')} name='f' id='object6' className="st1" d="M1849.49,888.52c0,0-1.58-356.44,0-353.47c1.58,2.97-85.45-7.32-85.45-7.32v-30.07l-248.44-13.45l-54.59,4.75
        v30.07h-13.45v291.96l338.64,112.35L1849.49,888.52z"/>
      <polygon onClick={() => navigate('/jehona/building/e')} name='e' id='object5' className="st1" points="1222.46,479.46 1286.95,474.73 1298.02,473.92 1515.6,484.21 1461.01,488.96 1461.01,519.02 
        1447.56,519.02 1447.56,810.98 1391.38,794.36 1391.38,670.14 1336.79,636.12 1324.92,636.12 1324.92,630.58 1290.11,608.43 
        1219.69,608.43 "/>
      <polygon onClick={() => navigate('/jehona/building/d')} id='object4' name='d' className="st1" points="1286.95,462.85 1180.92,459.68 1032.97,462.85 1077.27,466.8 1077.27,607.24 1149.27,608.43 
        1145.32,600.52 1210.99,600.52 1219.69,608.43 1222.46,479.46 1286.95,474.73 "/>
    </svg>
      {Object.keys(apartments ? apartments : {})?.map((key) => (
        <GenericPopOver key={key} anchorEl={popover[`anchorEl${key}`]} item={apartments ? {count: apartments[key].count, key:key.toUpperCase()} : null} />
      ))}
      {/* <GenericPopOver anchorEl={object2} item={popover.item} />
      <GenericPopOver anchorEl={object3} item={popover.item} />
      <GenericPopOver anchorEl={object4} item={popover.item} />
      <GenericPopOver anchorEl={object5} item={popover.item} />
      <GenericPopOver anchorEl={object6} item={popover.item} />
      <GenericPopOver anchorEl={object7} item={popover.item} />
      <GenericPopOver anchorEl={object1} item={apartments !== null ? apartments?.groupedData['A'].count : null} /> */}
    </Box>
  );
}

export default JehonaBuilding