import { Box, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import SmallDevice from '../components/svgHolder/jehona/SmallDevice'
import RegularDevice from '../components/svgHolder/jehona/RegularDevice'


const JehonaApartments = () => {
  const theme = useTheme()
  const isSmallDev = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box sx={{
      height: '100%'
    }}>
      {isSmallDev ? <SmallDevice /> : <RegularDevice />}
    </Box>
  )
}

export default JehonaApartments