import { Apartment, Bookmark, Close, Phone, PictureAsPdf, RemoveRedEye, Weekend } from '@mui/icons-material'
import { Box, Button, Dialog, Divider, Fade, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, {  useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkIfIsInWishList, getWishListItems, handleRefreshWishListState, handleWishlistItem } from '../../../features/wishList/WishListSlice'
import { getApartmentDetailModalData, getApartmentDetailModalState, handleApartmentDetailModalState } from '../../../features/apartment/ApartmentDetailSlice'
import { imagePath, mainUrl } from '../../../utils/consts'
import Calculator from '../../calculator/Calculator'
import { apartmentDetailModal, customFontFamily } from '../../../utils/theme'
import { getCalculatorModalState, handleCalculatorModalState } from '../../../features/calculator/CalculatorSlice'
import TestImg from '../../../B1-nav.png';

const ApartmentDetailsModalRegularDev = () => {

    const [isWishList, setWishList] = useState(false);
    const wishListItems = useSelector(getWishListItems);
    const calculatorModalIsOpened = useSelector(getCalculatorModalState)
    const data = useSelector(getApartmentDetailModalData);
    const theme = useTheme()
    const open = useSelector(getApartmentDetailModalState)
    
    const [calculatorOpen, setCalculatorOpen] = useState(false)
    const dispatch = useDispatch()
    const isSmallDev = useMediaQuery(theme.breakpoints.down("md"));
    const [planMetricNames, setPlanMetricNames] = useState([])
    const id = 1;

  const handleWishList = () => {
    if(checkIfIsInWishList(id)) {
      const storedArray = JSON.parse(localStorage.getItem('wish')) || [];
    if(storedArray?.length > 0){
      const filteredList = storedArray.filter((item) => item !== id)
      
      localStorage.setItem('wish', JSON.stringify(filteredList))
      setWishList(false)
      dispatch(handleWishlistItem(wishListItems.filter((item) => item !== id)))
    }
    }
    else{
      const storedArray = JSON.parse(localStorage.getItem('wish')) || [];
    if(storedArray?.length > 0){
      storedArray.push(id)
      
      localStorage.setItem('wish', JSON.stringify(storedArray))
    }
    else{
      const wishListToAdd = [
        id
      ]
      localStorage.setItem('wish', JSON.stringify(wishListToAdd))
    }
    setWishList(true)
    let wishToAdd = [...wishListItems];
    wishToAdd.push(id)
    dispatch(handleWishlistItem(wishToAdd))
    }
    dispatch(handleRefreshWishListState())
  }
  useEffect(() => {
     if(checkIfIsInWishList(id)){
      setWishList(true)
     }
  }, [])
  useEffect(() => {
    setPlanMetricNames(data.planMetric?.name.split(','))
 }, [data])

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallDev ? '100%' : '100%',
    height: isSmallDev ? '100%' : '100vh',
    bgcolor: "background.paper",
    boxShadow: 40,
    p: isSmallDev ? 2 : 0,
    overflow: 'hidden',
    
  };

  return (
    <Dialog fullScreen open={open} closeAfterTransition>
      <Fade in={open}>
        <Box sx={{ ...style, transition: "opacity 0.9s ease-in-out" }}>
          <Box
            sx={{
              ...apartmentDetailModal.main,
            }}
          >
            <IconButton
              sx={{
                ...apartmentDetailModal.iconButtonStyle,
              }}
              onClick={() => dispatch(handleApartmentDetailModalState(false))}
            >
              <Close fontSize="large" />
            </IconButton>
            <Box
              sx={{
                ...apartmentDetailModal.container,
              }}
            >
              <Box
                sx={{
                  ...apartmentDetailModal.leftContainer,
                }}
              >
                <Box flex={1}></Box>
                <Box
                  sx={{
                    ...apartmentDetailModal.detailBoxContainer,
                  }}
                >
                  <Box
                    sx={{
                      ...apartmentDetailModal.detailBox,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        justifyContent: "center",
                        mb: 2,
                        color: "black",
                        width: "90%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          justifyContent: "flex-start",
                          color: "black",
                          width: "90%",
                        }}
                      >
                        <Typography
                          fontWeight={"bold"}
                          variant="h1"
                          fontSize={"50px"}
                          fontFamily={"Montserrat"}
                        >
                          {data.name} - {' '}
                          {typeof data.square === "number" &&
                            parseInt(data.square).toFixed(2)}{" "}
                          m<sup>2</sup>
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                        width={"90%"}
                      >
                        <Divider />
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            color: "black",
                            mr: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Weekend fontSize="medium" />
                            <Typography
                              fontFamily={customFontFamily}
                              fontSize={'16px'}
                            >
                              {"Dhoma:".toUpperCase()}
                            </Typography>
                          </Box>
                          <Typography
                            fontWeight={"bold"}
                            fontFamily={customFontFamily}
                            fontSize={'16px'}
                          >
                            {data.rooms}
                          </Typography>
                        </Box>
                        <Divider />
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            color: "black",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Apartment fontSize="medium" />
                            <Typography
                              fontFamily={customFontFamily}
                              fontSize={'16px'}
                            >
                              {"Kati:".toUpperCase()}
                            </Typography>
                          </Box>
                          <Typography
                            fontWeight={"bold"}
                            fontFamily={customFontFamily}
                            fontSize={'16px'}
                          >
                            {data.floorNumber}
                          </Typography>
                        </Box>
                        <Divider />
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            color: "black",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Weekend fontSize="medium" />
                            <Typography
                              fontFamily={customFontFamily}
                              fontSize={'16px'}
                            >
                              {"Ndërtesa: ".toUpperCase()}
                            </Typography>
                          </Box>
                          <Typography
                            fontWeight={"bold"}
                            fontFamily={customFontFamily}
                            fontSize={'16px'}
                          >
                            1
                          </Typography>
                        </Box>
                        <Divider />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        ...apartmentDetailModal.contactUsButtonContainer,
                      }}
                    >
                      <Box
                        sx={{
                          width: "90%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            height: 25,
                            width: 25,
                            borderRadius: "50px",
                            backgroundColor: "#D9D9D9",
                            color: "rgba(101, 152, 77, 0.5)",
                            p: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            userSelect: "none",
                          }}
                        >
                          <Phone fontSize="medium" />
                        </Box>
                        <Box
                          sx={{
                            ...apartmentDetailModal.contactUsButton,
                          }}
                        >
                          <Typography fontSize={'16px'} textAlign={"center"}>
                            Na Kontaktoni
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    mb: 1
                  }}
                >
                  <Box
                    
                    display={"flex"}
                    width={"90%"}
                    mb={2}
                    justifyContent={"space-between"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                      }}
                    >
                      <IconButton
                      
                        sx={{
                          border: "1px solid #2D4322",
                        }}
                      >
                        <Bookmark fontSize="medium" />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          window.open(`${mainUrl}objektetImg/pdf`, "_blank");
                        }}
                      
                        sx={{
                          border: "1px solid #2D4322",
                          borderRadius: "50%",
                        }}
                      >
                        <PictureAsPdf fontSize="medium" />
                      </IconButton>
                    </Box>
                    <Box
                      onClick={() => {
                        dispatch(handleCalculatorModalState(true));
                      }}
                      sx={{
                        ...apartmentDetailModal.calculateButton,
                      }}
                    >
                      <Typography fontSize={'16px'}>Kalkulo</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  ...apartmentDetailModal.rightContainer,
                }}
              >
                <img
                  src={`${imagePath}${data.imageUrl}`}
                  width={"75%"}
                  height={"75%"}
                  style={{
                    objectFit: "fill",
                  }}
                />
                <Box>
                  <Button
                    startIcon={<RemoveRedEye />}
                    variant="contained"
                    sx={{
                      backgroundColor: "rgba(101, 152, 77, 0.5)",
                      border: "1px solid black",
                      fontFamily: "Montserrat, sans-serif",
                      padding: "5px 15px 5px 15px",
                      color: "black",
                      fontSize: "13px",
                      mt: 2,
                      ":hover": {
                        backgroundColor: "rgba(101, 152, 77, 0.5)",
                      },
                    }}
                  >
                    Pamja 3D
                  </Button>
                </Box>
                <Box sx={{
                  position: 'absolute',
                  right: 2,
                  bottom: 2,
                  boxShadow: '1px 1px 50px rgba(200, 200, 200, 0.12)',
                  borderRadius: 1,
                  ':hover' : {
                    animationPlayState: 'paused'
                  }
                }}>
                  <img width={150} height={140} src={TestImg}/>
                </Box>
              </Box>
            </Box>
          </Box>
          {calculatorModalIsOpened ? <Calculator /> : null}
        </Box>
      </Fade>
    </Dialog>
  )
}

export default ApartmentDetailsModalRegularDev