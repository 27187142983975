import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { customFontFamily } from '../../../../utils/theme'
import { useDispatch } from 'react-redux'
import { setPlanFilterLocation } from '../../../../features/filter/FilterSlice'

const CustomLocationFilter = () => {
    const dispatch = useDispatch();
    const handleLocation = (neighbor) => {
        dispatch(setPlanFilterLocation(neighbor))
    }
  return (
    <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        py: 1
    }}>
        <Box onClick={() => {handleLocation('Jehona')}} sx={{
            cursor: 'pointer',
            userSelect: 'none'
        }}>
            <Typography fontFamily={customFontFamily} textAlign={'center'}>Prishtina e re</Typography>
        </Box>
        <Divider sx={{
            my: 1
        }}/>
        <Box onClick={() => {handleLocation('Lagja Kalter')}} sx={{
            cursor: 'pointer',
            userSelect: 'none'
        }}>
            <Typography fontFamily={customFontFamily} textAlign={'center'}>Rruga C</Typography>
        </Box>
        <Divider sx={{
            my: 1
        }}/>
        <Box onClick={() => {handleLocation('Tophane')}} sx={{
            cursor: 'pointer',
            userSelect: 'none'
        }}>
            <Typography fontFamily={customFontFamily} textAlign={'center'}>Tophane</Typography>
        </Box>
    </Box>
  )
}

export default CustomLocationFilter