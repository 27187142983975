export const calculatorStyles = {
    container: {
        width: '400px',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '25px',
        backgroundColor: 'white',
        position: 'absolute',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;',
        top: '50%',
        left: '50%',
        transform: "translate(-50%, -50%)",
        zIndex: 999999
    },
    headerSection: {
        width: '100%',
        height: '50px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    closeContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#65984d',
        color: 'white',
        p: 1,
    },
    mainSection: {
        flexGrow: 10,
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: 1,
    },
    mainInnerSection: {
        width: '75%',
        backgroundColor: 'white',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mt: 1,
    },
    priceBox: {
        width: '100%',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '30px',
        backgroundColor: '#f2f2f2'
    },
    priceInfo: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',        
    },
    priceDetailBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#003057',
        borderRadius: '20px',
        color: 'white',        
    },
}
