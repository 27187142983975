import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/consts";
import { tokenConfig } from "../auth/AuthSlice";

const url = `${BASE_URL}/api/calculator`;

export const getCalculatorData = createAsyncThunk(
    'CalculatorSlice/getdata',
    async(a, { rejectWithValue }) => {
        try {
            const res  = await axios.get(`${url}`, tokenConfig());
            return res.data;
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)