import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getFilterData, handleFilterState, setFilterRoom } from '../../../../features/filter/FilterSlice';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const roomBoxButtonStyle = {
    marginTop: 1,
    userSelect: "none",
    ":hover": {
      backgroundColor: "rgba(5, 98, 0, 1)",
      cursor: "pointer",
      color: 'white'
    },
    ":active": {
      backgroundColor: "rgba(5, 98, 0, 1)",
      color: "white",
    },
  };

const CustomRoomFilter = () => {
  const dispatch = useDispatch();
  const filterData = useSelector(getFilterData);
  const themee = useTheme();
  const isSmallDev = useMediaQuery(themee.breakpoints.down("md"));
  return (
    <Box p={1} display={"flex"} justifyContent={'center'} alignItems={"center"} gap={1}>
    <Box
      display={"flex"}
      gap={2}
      width={'100%'}
      justifyContent={isSmallDev ? "center" : ""}
    >
      <Box display={"flex"} gap={2} flexDirection={"row"}>
        <Box
          width={40}
          height={40}
          border={"3px solid rgba(5, 98, 0, 1);"}
          bgcolor={
            filterData?.rooms?.includes("1+1") ? "rgba(5, 98, 0, 1)" : ""
          }
          color={filterData?.rooms?.includes("1+1") ? "white" : "black"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          borderRadius={"10px"}
          sx={{ ...roomBoxButtonStyle }}
          onClick={() => {
            dispatch(setFilterRoom("1+1"));
            dispatch(handleFilterState(true))
          }}
        >
          <Typography sx={{ fontFamily: "Montserrat", fontSize: "24px" }}>
            1
          </Typography>
        </Box>
        <Box
          width={40}
          height={40}
          border={"3px solid rgba(5, 98, 0, 1);"}
          borderRadius={"10px"}
          bgcolor={
            filterData?.rooms?.includes("2+1") ? "rgba(5, 98, 0, 1)" : ""
          }
          color={filterData?.rooms?.includes("2+1") ? "white" : "black"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={roomBoxButtonStyle}
          onClick={() => {
            dispatch(setFilterRoom("2+1"));
            dispatch(handleFilterState(true))
          }}
        >
          <Typography sx={{ fontFamily: "Montserrat", fontSize: "24px" }}>
            2
          </Typography>
        </Box>
      </Box>
      <Box display={"flex"} gap={2} flexDirection={"row"}>
        <Box
          width={40}
          height={40}
          border={"3px solid rgba(5, 98, 0, 1);"}
          borderRadius={"10px"}
          bgcolor={
            filterData?.rooms?.includes("3+1") ? "rgba(5, 98, 0, 1)" : ""
          }
          color={filterData?.rooms?.includes("3+1") ? "white" : "black"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={roomBoxButtonStyle}
          onClick={() => {
            dispatch(setFilterRoom("3+1"));
            dispatch(handleFilterState(true))
          }}
        >
          <Typography sx={{ fontFamily: "Montserrat", fontSize: "24px" }}>
            3
          </Typography>
        </Box>
        <Box
          width={40}
          height={40}
          border={"3px solid rgba(5, 98, 0, 1);"}
          borderRadius={"10px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          bgcolor={
            filterData?.rooms?.includes("4+1") ? "rgba(5, 98, 0, 1)" : ""
          }
          color={filterData?.rooms?.includes("4+1") ? "white" : "black"}
          sx={roomBoxButtonStyle}
          onClick={() => {
            dispatch(setFilterRoom("4+1"));
            dispatch(handleFilterState(true))
          }}
        >
          <Typography sx={{ fontFamily: "Montserrat", fontSize: "24px" }}>
            4
          </Typography>
        </Box>
      </Box>
    </Box>
  </Box>
  )
}

export default CustomRoomFilter