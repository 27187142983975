import { Done } from '@mui/icons-material';
import axios from 'axios';
import React, { useState } from 'react';
import { BASE_URL } from '../../utils/consts';
import { tokenConfig } from '../../features/auth/AuthSlice';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const BuildingSvgExtractor = ( { show } ) => {
  const [svgCode, setSvgCode] = useState('');
  const[elementIndex, setElementIndex] = useState(0);
  const [selectedTags, setSelectedTags] = useState(['', ''])
  const [extractedElements, setExtractedElements] = useState(
    {
      buildingName: "",
      buildingNumber: "",
      buildingSide: 0,
      imageUrl: "",
      imageWidth: "",
      imageHeight: "",
      imageTransform: "",
      viewBoxStyle: "",
      maxFloor: 0,
      floorNumber: '',
      path: []
    }
  );

  useEffect(() => {
    console.log(extractedElements.viewBoxStyle)
  }, [extractedElements])

  const handleSvgCodeChange = (event) => {
    setSvgCode(event.target.value);
  };

  const [inputVal, setInputVal] = useState({
    name: '',
    apartmentNumber: '',
    rooms: '',
    floor: 0,
    square: 0,
    balconySquare: 0,
    buildingSide: 0,
    isSold: false,
    buildingNr: '',
    maxFloor: 0,
  })
  
  const extractSvgElements = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgCode, 'image/svg+xml');
    let elements = {};
    const pathsList = []
    const svgElement = doc.querySelector('svg');
    if (svgElement) {
      const viewBox = svgElement.getAttribute('viewBox');
      elements.viewBox = viewBox
      console.log('View Box' + elements.viewBox)
    }
    const imgElement = doc.querySelector('image');
    if (imgElement) {
      const width = imgElement.getAttribute('width');
      const height = imgElement.getAttribute('height')
      const transform = imgElement.getAttribute('transform')
      elements.imageHeight = height
      elements.imageWidth = width
      if(transform){
        elements.imageTransform = transform
      }
    }
    let array = []
    selectedTags.forEach((tag) => {
      if(tag.length > 1) {
        
        const selectedElements = doc.querySelectorAll(tag);
        selectedElements.forEach((element) => {
          const attributes = Array.from(element.attributes);
          const attributesObject = attributes.reduce((acc, attr) => {
            
            if(attr.name === 'points' || attr.name === 'd'){
              acc["path"] = attr.value
              
              if(attr.name === 'points'){
                acc['pointsType'] = 'polygon';
              }
              if(attr.name === 'd'){
                acc['pointsType'] = 'path';
              }
              return acc;
            }
            acc[attr.name] = attr.value;
            return acc;
          }, {});
          array.push(attributesObject)
        });
          setExtractedElements({
            ...extractedElements,
            path: array,
            imageHeight: elements.imageHeight,
            imageWidth: elements.imageWidth,
            imageTransform: elements.imageTransform,
            viewBoxStyle: elements.viewBox
          })
        }
      });
   };
  const handleAddAdditionalAttrToList = () => {
    const arrayToSave = extractedElements.path[elementIndex];
    arrayToSave['name'] = inputVal.name
    arrayToSave['square'] = inputVal.square
    arrayToSave['floor'] = inputVal.floor
    arrayToSave['rooms'] = inputVal.rooms
    arrayToSave['balconySquare'] = inputVal.balconySquare
    
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      path: extractedElements.path,
      buildingName: extractedElements.buildingName,
      buildingNumber: extractedElements.buildingNumber,
      buildingSide: extractedElements.buildingSide,
      imageUrl: extractedElements.imageUrl,
      imageWidth: extractedElements.imageWidth,
      imageHeight: extractedElements.imageHeight,
      imageTransform: extractedElements.imageTransform,
      viewBoxStyle: extractedElements.viewBoxStyle,
      maxFloor: extractedElements.maxFloor,
      floorNumber: extractedElements.floorNumber,
    };
    const responseWrapper = (data) =>
    axios.post(`${BASE_URL}/api/building/create`, data, tokenConfig());
  
    toast.promise(responseWrapper(data), {
      pending: 'Kërkesa juaj është duke u procesuar!',
      success: 'Dokumenti është ruajtur me sukses',
      error: 'Ruajtja e dokumentit ka deshtuar',
      position: 'top-right'
    });
        
    }
  
  function generateRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const handleRandomGenerateAll = () => {
      const randomNumber =  `Njesia ${generateRandomNumber(2, 20)}`;
      extractedElements.buildingName = randomNumber;
      const nr =  Math.floor(Math.random() * 100) + 1;
      extractedElements.buildingNumber = nr
      setExtractedElements({
        ...extractedElements,
        
        
      })
  }

  const addBuildingSide = () => {
    const arrayToSave = extractedElements.path;

    for (let i = 0; i < arrayToSave.length; i++) {
      arrayToSave[i].buildingSide = inputVal.buildingSide;
    }
    setExtractedElements({
      ...extractedElements,
    });
  }
const addBuildingNr = () => {
    
  const arrayToSave = extractedElements.path;

  for (let i = 0; i < arrayToSave.length; i++) {
    arrayToSave[i].buildingNr = inputVal.buildingNr
}
setExtractedElements({
  ...extractedElements,
  
  
})
}
const handleCheckboxChange = (e) => {
  const { value, checked } = e.target;
  if (checked) {
    setSelectedTags(prevItems => {
      if (prevItems.includes(value)) {
        return prevItems;
      } else {
        return [...prevItems, value];
      }
    });
  } else {
    setSelectedTags(prevItems => prevItems.filter(item => item !== value));
  }
};
  return (
    <div style={{ display: show ? "block" : "none", marginTop: 10 }}>
      <h2>Shto Ndertesen dhe katet</h2>
      <div style={{ display: "flex", gap: 20 }}>
        <div>
          <textarea
            rows={10}
            cols={50}
            placeholder="Paste SVG code here"
            value={svgCode}
            onChange={handleSvgCodeChange}
          />
          <div>
            <label>
              <input type="checkbox" onChange={handleCheckboxChange} value="image" /> Image
            </label>
            <label>
              <input type="checkbox" onChange={handleCheckboxChange} value="path" /> Path
            </label>
            <label>
              <input type="checkbox" onChange={handleCheckboxChange} value="polygon" /> Polygon
            </label>
          </div>
          <button
            onClick={() => {
              extractSvgElements();
            }}
          >
            Extract Elements
          </button>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <div>
            <div style={{ display: "flex", gap: 20 }}>
              <label>Antari: {elementIndex}</label>
              <label>
                Gjithsej: {extractedElements.paths?.length} Antar ne List
              </label>
              <label>
                {extractedElements.paths?.length - elementIndex} Antar Te Mbetur
              </label>
            </div>
            <div>
              <button
                onClick={() => {
                  if (elementIndex >= extractedElements.paths?.length) {
                    return;
                  }
                  setElementIndex(elementIndex + 1);
                  const arrayToSave = extractedElements?.paths[elementIndex];
                  setInputVal({
                    ...inputVal,
                    square: arrayToSave["square"],
                    name: arrayToSave["name"],
                    floor: arrayToSave["floor"],
                    rooms: arrayToSave["rooms"],
                    balconySquare: arrayToSave["balconySquare"],
                  });
                }}
              >
                Antari Radhes
              </button>
            </div>
          </div>
          <div style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
            <label style={{ display: "flex", flexDirection: "column" }}>
              Siperfaqja
              <input
                onChange={(e) => {
                  setInputVal({
                    ...inputVal,
                    square: e.currentTarget.value,
                  });
                }}
                value={inputVal.square}
                type="text"
                name="square"
              />
            </label>
            <label style={{ display: "flex", flexDirection: "column" }}>
              Kati
              <input
                onChange={(e) => {
                  setInputVal({
                    ...inputVal,
                    floor: e.currentTarget.value,
                  });
                }}
                value={inputVal.floor}
                type="text"
                name="floor"
              />
            </label>
            <label style={{ display: "flex", flexDirection: "column" }}>
              Tipi
              <input
                onChange={(e) => {
                  setInputVal({
                    ...inputVal,
                    rooms: e.currentTarget.value,
                  });
                }}
                value={inputVal.rooms}
                type="text"
                name="rooms"
              />
            </label>
            <label style={{ display: "flex", flexDirection: "column" }}>
              Ndertesa
              <input
                onChange={(e) => {
                  setExtractedElements({
                    ...extractedElements,
                    buildingNumber: e.currentTarget.value,
                  });
                }}
                value={extractedElements.buildingNumber}
                type="text"
                name="buildingNumber"
              />
            </label>
            <label style={{ display: "flex", flexDirection: "column" }}>
              Nr. Baneses
              <input
                onChange={(e) => {
                  setInputVal({
                    ...inputVal,
                    apartmentNumber: e.currentTarget.value,
                  });
                }}
                value={inputVal.apartmentNumber}
                type="text"
                name="apartmentName"
              />
            </label>
            <label style={{ display: "flex", flexDirection: "column" }}>
              Terrasa
              <input
                onChange={(e) => {
                  setInputVal({
                    ...inputVal,
                    balconySquare: e.currentTarget.value,
                  });
                }}
                value={inputVal.balconySquare}
                type="text"
                name="balconySquare"
              />
            </label>
            <label style={{ display: "flex", flexDirection: "column" }}>
              Ana e Baneses
              <input
                onChange={(e) => {
                  setExtractedElements({
                    ...extractedElements,
                    buildingSide: e.currentTarget.value,
                  });
                }}
                value={extractedElements.buildingSide}
                type="text"
                name="buildingSide"
              />
            </label>
            <label style={{ display: "flex", flexDirection: "column" }}>
              Emri Baneses
              <input
                onChange={(e) => {
                  setExtractedElements({
                    ...extractedElements,
                    buildingName: e.currentTarget.value,
                  });
                }}
                value={extractedElements.buildingName}
                type="text"
                name="apartmentName"
              />
            </label>
            <label style={{ display: "flex", flexDirection: "column" }}>
              Kati maksimal
              <input
                onChange={(e) => {
                  setExtractedElements({
                    ...extractedElements,
                    maxFloor: parseInt(e.currentTarget.value),
                  });
                }}
                value={extractedElements.maxFloor}
                type="number"
                name="maxFloor"
              />
            </label>
            <label style={{ display: "flex", flexDirection: "column" }}>
              Url e fotos
              <input
                onChange={(e) => {
                  setExtractedElements({
                    ...extractedElements,
                    imageUrl: e.currentTarget.value,
                  });
                }}
                value={extractedElements.imageUrl}
                type="text"
                name="urlImg"
              />
            </label>
            <label>
              <input type="checkbox" name="isSold" />
              Shitur
            </label>
          </div>

          <div
            style={{
              display: "flex",
              gap: 10,
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", gap: 10 }}>
              <div>
                <button onClick={handleAddAdditionalAttrToList}>
                  Shto ne List
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    handleRandomGenerateAll();
                  }}
                >
                  Gjenero Random Vlerat
                </button>
              </div>
              <div>
                <button onClick={addBuildingSide}>Shto Building Side</button>
              </div>
              <div>
                <button onClick={addBuildingNr}>Shto Building Number</button>
              </div>
            </div>
            <div>
              <button
                onClick={handleSubmit}
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "500px",
                }}
              >
                <Done />
                Ruaj Ne Server
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          overflow: "auto",
          maxWidth: "fit-content",
          maxHeight: "fit-content",
          height: "500px",
          marginTop: 50,
          userSelect: "text",
        }}
      >
        Lista perfundimtare:
        <pre>{JSON.stringify(extractedElements, null, 2)}</pre>
      </div>
    </div>
  );
}

export default BuildingSvgExtractor