import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    modalOpened: false,
    modalData: {},
    data: [],
    msg: '',
    status: 'idle',
}

const SalesSlice = createSlice({
    name: 'SalesSlice',
    initialState,
    reducers: {
        handleSalesModalState(state, action) {
            state.modalOpened = action.payload;
        },
        handleSalesModalData(state, action) {
            state.modalData = action.payload;
        },
    }
})

export const getSalesModalState = (state) => state.SalesSlice.modalOpened;
export const getSalesModalData = (state) => state.SalesSlice.modalData;
export const getSalesData = (state) => state.SalesSlice.data;
export const getSalesStatus = (state) => state.SalesSlice.status;
export const getSalesMsg = (state) => state.SalesSlice.msg;
export const {
    handleSalesModalData,
    handleSalesModalState
} = SalesSlice.actions;
export default SalesSlice.reducer;