import React, { useState } from 'react';
import { Button } from '@mui/material';
import ApartmentsSvgExtractor from '../components/svgExtractor/ApartmentsSvgExtractor';
import BuildingSvgExtractor from '../components/svgExtractor/BuildingSvgExtractor';

const SvgExtractor = () => {
  const [buildingWindows, setBuildingWindows] = useState(false)
  
  
  return (
    <div style={{margin: 5, padding: 5}}>
      <Button onClick={() => setBuildingWindows(true)}>Ndertesa</Button>
      <Button onClick={() => setBuildingWindows(false)}>Apartamenti</Button>
      {/* ndertesa */}
      <ApartmentsSvgExtractor show={buildingWindows}/>

      {/* apartamenti */}
      <BuildingSvgExtractor show={buildingWindows}/>
    </div>
  );
};

export default SvgExtractor;
