import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import SmallDevice from '../components/tophane/SmallDevice'
import RegularDevice from '../components/tophane/RegularDevice'
import Navbar from '../../navbar/Navbar'

const SvgHolder = () => {
    const theme = useTheme()
    const isSmallDev = useMediaQuery(theme.breakpoints.down('md'))
  return (
   <div style={{overflowX: 'hidden'}}>
   <Navbar />
    {
        isSmallDev ? <SmallDevice /> : <RegularDevice />
    }
   </div>
  )
}

export default SvgHolder