import { createSlice } from "@reduxjs/toolkit"
import { getCalculatorData } from "./CalculatorAPI"

const initialState = {
    isOpen: false,
    msg: '',
    status: 'idle',
    data: null,
}

const CalculatorSlice = createSlice({
    name: 'CalculatorSlice',
    initialState,
    reducers: {
        handleCalculatorModalState(state, action) {
            state.isOpen = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCalculatorData.pending , (state, action) => {
                    state.status = 'pending';
                })
               .addCase(getCalculatorData.fulfilled , (state, action) => {

               })
               .addCase(getCalculatorData.rejected , (state, action) => {

               })
    }
})
export const {
    handleCalculatorModalState,
} = CalculatorSlice.actions
export const getCalculatorModalState = (state) => state.CalculatorSlice.isOpen;
export default CalculatorSlice.reducer