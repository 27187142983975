export const colors = {
    filterButton: '#333',
    filterIcon: 'green',
    navBarBackgroundColor: '#338441',
    lightGreen: '#65984D',
    darkGreen: '#527540',
}

export const generalPopUp = {
    container: {
        width: 200,
        heigth: 400,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#65984d',
        borderRadius: 3,
        padding: 2,
        position: 'relative',
        cursor: 'pointer'
    }
}

export const cardStyle = {
    wrapper: {
        borderRadius: '30px',
        width: '100%',
        height: 370,
        border: '1px solid lightgrey',
        transition: 'all 0.5s ease-in-out',
        overflow: 'hidden',
        lineHeight: 1.1
    },
    gradient: {
        backgroundImage: 'linear-gradient(180deg, rgba(40, 91, 16, 0.4), rgba(0,48,87,0))',
        position: 'absolute',
        transform: 'rotate(-180deg)',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '30%',
    },
    text: {
        position: 'absolute',
        bottom: 5,
        left: 15,
        color: 'white',
        fontSize: '20px',
        fontWeight: 550,
    },
    imageContainer: {
        width: '100%',
        height: 300,
        position: 'relative',
        borderRadius: '30px',
        overflow: 'hidden',
    },
}

export const projectFilter = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
    },
    cardContainer:{
        border: `1px solid ${colors.darkGreen}`,
        borderRadius: '50px',
        p: 1,
        pl: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontFamily: 'Montserrat',
        fontSize: '18px',
        width: '100%',
        transition: 'all 0.2s ease-in-out',
        flex: {
            md: 3,
            lg: 2,
        },
        ':hover': {
            border: `1px solid rgb(0, 65, 0)`,  
        }
    },
}

export const generalPopOver = {
    container: {
        width: 90,
        heigth: 150,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#65984d',
        borderRadius: 3,
        padding: 1,
        position: 'relative',
        cursor: 'pointer'
    }
}

export const apartmentPopover = {
    container: {
        width: 250,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#65984d',
        color: 'white',
        borderRadius: 3,
        position: 'relative',
        cursor: 'pointer'
    }
}

export const jehonaFilterPopover = {
    container: {
        width: 300,
        display: 'flex',
    }
}
export const apartmentDetailModal = {
    main: {
        display: "flex",
        flexDirection: "column",
        height: {
            md: "100%",
            xl: "100%",
        },
        width: "100%",
    },
    container: {
        display: "flex",
        height: {
            md: "100%",
            xl: "100%",
        },
        width: "100%",
        flexDirection: {
            xs: 'column',
            md: 'row'
        }
    },
    leftContainer: {
        flex: {
            xs: 1,
            md: 4,
          },
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundColor: "rgba(101, 152, 77, 0.5)",
          p: {
            md: 1
          },
          color: "white",
          width: '100%'
    },
    rightContainer: {
        flex: {
            xs: 1,
            md: 6,
          },
          height: "100%",
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
    },
    iconButtonStyle: {
        border: "1px solid green",
        borderRadius: "50px",
        color: "green",
        position: 'absolute',
        top: 5,
        right: 5,
        m: 1,
        zIndex: 9999999,
    },
    calculateButton: {
        width: '50%',
        p: 1,
        border: '1px solid #2E4523',
        borderRadius: 3,
        display: 'flex',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        justifyContent: 'center',
        color: '#2E4523',
        alignItems: 'center',
        userSelect: 'none',
        cursor: 'pointer',
        ':hover' : {
        boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.1)'
        },
    },
    contactUsButton: {
        width: "60%",
        backgroundColor: "rgba(107, 152, 85, 0.78)",
        p: 1,
        height: 25,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        ':hover' : {
        boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.1)'
        },
    },
    contactUsButtonContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    detailBoxContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 10,
        height: "100%",
    } ,
    detailBox: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 2,
    }

}
export const navbarStyle = {
    main: {
        display: {
            xs: 'none', 
            md: 'flex'
        }, 
        flexDirection: 'row', 
        justifyContent: {md: 'space-between'},
        alignItems: 'center',
        gap: 1,
        width: '100%',
    },
    menuBox: {
        display: 'flex',
        gap: 1,
        borderRadius: '50px',
        p: '15px 30px 15px 30px',
        fontFamily: '"Montserrat", Sans-serif',
        backgroundColor: '#6B9855C9',
        alignItems: 'center',
        cursor: 'pointer', 
        border: '2px solid white'
    },
    rightContainer: {
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        mr: '25px',
        color: 'black'
    },
    playArrowBox: {
        width: 44,
        height: 44,
        borderRadius: '50px',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #527540',
        backgroundColor: 'white',
        justifyContent: 'center',
        color: '#527540',
        cursor: 'pointer',
        userSelect: 'none',
    },
    threedBox: {
        width: {
            xs: 35,
            md: 44
        },
        height: {
            xs: 35,
            md: 44
        },
        borderRadius: '50px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#527540',
        justifyContent: 'center',
        color: 'white',
        cursor: 'pointer',
        userSelect: 'none',
    },
    apartmentSelectBox: {
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        fontSize: '13px',
        borderRadius: 7,
        border: '1px solid #527540',
        p: 2,
        px: 5,
        backgroundColor: 'white',
        color: '#527540',
    },
    contactPhoneBox: {
        width: 44,
        height: 44,
        borderRadius: '50px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        justifyContent: 'center',
        border: '2px solid #527540',
        color: '#527540',
        cursor: 'pointer',
        userSelect: 'none'
    }
}
export const customFontFamily = 'Montserrat, sans-serif';