import { Box, Popover, Typography } from '@mui/material'
import React from 'react'
import { generalPopOver } from '../../../utils/theme';

const GenericPopOver = ( { anchorEl, item } ) => {
    const open = Boolean(anchorEl)
  return (
    <Popover
      open={open}
      slotProps={{
        paper: {
          style:{
            backgroundColor: '#65984d'
          }
        }
      }}
      anchorEl={anchorEl?.current}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      sx={{
        pointerEvents: "none",
        "& .MuiPopover-paper": {
          borderRadius: "10px",
        },
        height: 350,
        overflow: "visible",
        
      }}
    >
      <Box sx={{ ...generalPopOver.container }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
          height={90}
        >
          <Box display={"flex"} alignItems={"flex-end"}>
            <Typography sx={{ color: "#4b7636", mr: 1 }} variant="h4">
              {item.key}
            </Typography>
            <Typography
              sx={{ color: "#4b7636", mt: 2 }}
              variant="h6"
            >
              Objekti
            </Typography>
          </Box>
          <Box display={"flex"} gap={1}>
            <Typography
              fontSize={"16px"}
              sx={{ color: "rgba(1, 127, 9, 1)" }}
              fontWeight={700}
              variant="h6"
            >
              {item ? item.count : 9}
            </Typography>
            <Typography fontSize={"16px"} variant="h6">
              {"Të lira".toUpperCase()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Popover>
  );
}

export default GenericPopOver