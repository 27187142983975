import { Box, Slider, Typography, styled } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getFilterData, setFilterFloor, setFilterSquare } from '../../../features/filter/FilterSlice';
import { colors } from '../../../utils/theme';

const CustomSliderStyled = styled(Slider)`
  .MuiSlider-thumb {
    width: 20px;
    height: 20px;
    background-color: white;
    border:1px solid rgba(5, 98, 0, 1);
    box-shadow: none;
  }
  .MuiSlider-thumb:hover{
    box-shadow: none;
  }
  .MuiSlider-thumb:active{
    box-shadow: none;
  }
  .MuiSlider-track {
    border: 1px solid rgba(5, 98, 0, 1);
  }
`;

const CustomSlider = ( { data } ) => {
  const dispatch = useDispatch();
  const filterData = useSelector(getFilterData);

  const handleChangeSquare = (event, newValue) => {
    dispatch(setFilterSquare(newValue));
  };
  const handleChangeFloor = (event, newValue) => {
    dispatch(setFilterFloor(newValue));
  };
  return (
    <Box p={3} width={"100%"}>
      <Typography
      fontFamily={'Montserrat'}
      fontSize={'20px'}
      sx={{
        ml: -1
      }}>{data === 'square' ? 'Sipërfaqja' : 'Kati'}</Typography>
      <CustomSliderStyled
        onChange={data === "square" ? handleChangeSquare : handleChangeFloor}
        min={data === 'square' ? 40 : 1}
        sx={{color: colors.lightGreen}}
        max={data === 'square' ? 300 : 10}
        value={
          data === "square"
            ? [filterData.square.startVal, filterData.square.endVal]
            : [filterData.floor.startVal, filterData.floor.endVal]
        }
      />
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        <Typography>{data === 'square' ? filterData.square.startVal + `m2` : filterData.floor.startVal}</Typography>
        <Typography>{data === 'square' ? filterData.square.endVal + `m2` : filterData.floor.endVal}</Typography>
      </Box>
    </Box>
  );
}

export default CustomSlider