import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getPlanFilterData, setPlanFilterSquare } from '../../../features/filter/FilterSlice';

const CustomSquareFieldFilter = () => {
  const dispatch = useDispatch();
  const filterData = useSelector(getPlanFilterData);

  const handleChangeSquare = (e) => {
    const { name, value } = e.currentTarget;
    if(name === 'start')
        dispatch(setPlanFilterSquare([value, filterData.square.endVal]))
    if(name === 'end')
        dispatch(setPlanFilterSquare([filterData.square.startVal, value]))
  };
  return (
    <Box display={"flex"} gap={1} alignItems={"center"}>
      <TextField
      value={filterData.square.startVal}
      onChange={handleChangeSquare}
      name='start'
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              m<sup>2</sup>
            </InputAdornment>
          ),
        }}
        variant="standard"
        sx={{
          p: 0,
        }}
      />

      <Typography>-</Typography>
      <TextField
      value={filterData.square.endVal}
      onChange={handleChangeSquare}
      name='end'
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              m<sup>2</sup>
            </InputAdornment>
          ),
        }}
        variant="standard"
        sx={{
          p: 0,
        }}
      />
    </Box>
  );
}

export default CustomSquareFieldFilter