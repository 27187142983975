import React from 'react'
import Navbar from '../components/navbar/Navbar'
import { Card } from '../components'
import WidthWrapper from '../components/common/WidthWrapper'
import { Box, Divider, Grid, Pagination, Typography } from '@mui/material'
import ProjectFilter from '../components/filter/ProjectFilter'
import { useDispatch, useSelector } from 'react-redux'
import { getObjectSvgData, getObjectsStatus } from '../features/objects/ObjectsSlice'
import { useEffect } from 'react'
import { getObjectSvgDataAll } from '../features/objects/ObjectsApi'
import { getMobilePlanFilterState, getPlanFilterData, handleMobilePlanFilterState } from '../features/filter/FilterSlice'
import { useState } from 'react'
import PageLoading from '../components/loading/PageLoading'
import { getApartmentDetailModalState, handleApartmentDetailModalData, handleApartmentDetailModalState } from '../features/apartment/ApartmentDetailSlice'
import ApartmentDetailsModal from '../components/modal/ApartmentDetailsModal'
import PlanCard from '../components/card/PlanCard'
import MobilePlanFilter from '../components/filter/jehona/MobilePlanFilter'
import { imagePath } from '../utils/consts'

const itemsPerPage = 9;

const ProjectsPlanMetrics = () => {
    const objectsData = useSelector(getObjectSvgData);
    const apartmentDetailsModalOpened = useSelector(getApartmentDetailModalState)
    const filterData = useSelector(getPlanFilterData);
    const mobileFilterModal = useSelector(getMobilePlanFilterState)
    const status = useSelector(getObjectsStatus)
    const [pageble, setPageble] = useState({
      size: 1,
      currentPage: 1
    })
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getObjectSvgDataAll(0))
    }, [dispatch])
    useEffect(() => {
      if(objectsData){
        setPageble(prev => ({
          ...prev,
          size: objectsData.length,
  
        }))
      }
    }, [objectsData])
    const handlePageChange = (event, value) => {
      setPageble(prev => ({
        ...prev,
        currentPage: value,

      }))
    }
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {status === 'objects_svg_getAll_pending' ? <PageLoading /> : null}
      <Navbar />
      <div
        style={{
          marginTop: "100px",
          marginLeft: "10px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: "90px",
        }}
      >
        <WidthWrapper>
          <Box width={"100%"} my={2}>
            <Typography
              fontFamily={"Montserrat"}
              textAlign={"center"}
              variant="h4"
            >
              Real Estate Apartments in DP Partners
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: {xs: 'none', md: 'block'}}}>
            <ProjectFilter />
            <Divider sx={{ my: 2 }} />
            </Box>
          </Box>
        </WidthWrapper>
        <WidthWrapper>
          <Grid container spacing={2} mb={5}>
            {objectsData?.map((item, index) => {
              if (
                (filterData.location.includes(item.neighborhood) ||
                  filterData.location.includes("all")) &&
                (filterData.rooms.includes(item.rooms) ||
                  filterData.rooms.includes("all")) &&
                item.square <= filterData.square.endVal &&
                item.square >= filterData.square.startVal
              ) {
                return (
                  <Grid item xs={12} md={12}>
                    <PlanCard
                    fullscreen
                      onClick={() => {
                        dispatch(handleApartmentDetailModalData(item))
                        dispatch(handleApartmentDetailModalState(true))
                      }}
                      imageUrl={
                        `${imagePath}/${item.imageData}`
                      }
                      description={item.buildingName}
                      data={item}
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination
              onChange={handlePageChange}
              count={pageble.size > itemsPerPage ? Math.floor(pageble.size / itemsPerPage + 1) : 1}
              variant="outlined"
              shape="rounded"
            />
          </Box>
        </WidthWrapper>
        <Box 
        onClick={() => dispatch(handleMobilePlanFilterState(true))}
        sx={{display: {xs: 'flex', md: 'none'}, justifyContent: 'center', alignItems: 'center', position: 'fixed', userSelect: 'none', cursor: 'pointer', color: 'white', width: '35%', bottom: 2, left: '30%', p: 1, px: 2, backgroundColor: '#65984d', borderRadius: 4}}>
            <Typography variant='h6'>Filtro</Typography>
        </Box>
        {apartmentDetailsModalOpened ? <ApartmentDetailsModal /> : null}
        {mobileFilterModal && <MobilePlanFilter />}
      </div>
    </div>
  );
}

export default ProjectsPlanMetrics