import { Icon, SvgIcon } from '@mui/material'
import React from 'react'

const IconWrapper = ( { svg, color } ) => {
  return (
    <SvgIcon sx={{
      fill: color
    }}>
        {svg}
    </SvgIcon>
  )
}

export default IconWrapper