import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Menu, Phone, PlayArrow, Search } from '@mui/icons-material';
import { colors, navbarStyle } from '../../utils/theme';
import { useNavigate } from 'react-router-dom';


const drawerWidth = 240;
const navItems = ['Ballina', 'Projektet', 'Zhvilluesit', 'Rreth nesh', 'Video', 'Galeria', 'Na kontaktoni'];
// const darkTheme = createTheme({
//   palette: {
//     mode: 'light',
//     primary: {
//       main: 'red',
//     },
//   },
// });

export default function Navbar(props) {
  const { window } = props;
  const navigate = useNavigate()
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const container = window !== undefined ? () => window().document.body : undefined;
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const appBarLabel = (
    <Toolbar>
      <Box sx={{ ...navbarStyle.main }}>
        <IconButton
          onClick={handleDrawerToggle}
          sx={{ display: { xs: "block", lg: "none" }, color: "white" }}
        >
          <Menu />
        </IconButton>
      
      <Box
        width={"100%"}
        justifyContent={"space-between"}
        sx={{
          display: { xs: "none", lg: "flex" },
          alignItems: "center",
          py: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 15,
            ml: "25px",
          }}
        >
          <Box
            sx={{
              ...navbarStyle.menuBox,
            }}
          >
            <Menu />
            MENU
          </Box>
          <Box>
            <img
              width={200}
              src="https://dp-partners.roitiv.com/wp-content/uploads/2023/10/1000x1000px-15.png"
            />
          </Box>
        </Box>
        <Box
          sx={{
            ...navbarStyle.rightContainer,
          }}
        >
          <Typography fontFamily={'Montserrat", Sans-serif'} fontSize={"15px"}>
            +383 44 167 969
          </Typography>
          <Box
            sx={{
              ...navbarStyle.playArrowBox,
            }}
          >
            <PlayArrow />
          </Box>
          <Box
            onClick={() => {
              navigate("/jehona/360");
            }}
            sx={{
              ...navbarStyle.threedBox,
            }}
          >
            3D
          </Box>
          <Box
            sx={{
              ...navbarStyle.apartmentSelectBox,
            }}
          >
            <Search sx={{ color: "#527540" }} fontSize="small" />
            {"Zgjedh Banesen".toUpperCase()}
          </Box>
          <Box
            sx={{
              ...navbarStyle.contactPhoneBox,
            }}
          >
            <Phone sx={{ color: "#527540" }} />
          </Box>
        </Box>
      </Box>
      </Box>
      {/* mobile */}

      <Box
        sx={{
          display: {
            xs: "flex",
            md: "none",
          },
          justifyContent: "space-between",
          width: '100%',
          alignItems: 'center'
        }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <Box
          sx={{
            display: {
              xs: "flex",
              md: "none",
            },
            width: 35,
            height: 35,
            color: "white",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#6B9855C9",
            borderRadius: "50px",
          }}
        >
          <Menu />
        </Box>
        <Box
          sx={{
            display: {
              xs: "block",
              md: "none",
            },
          }}
        >
          <img
            width={115}
            src="https://dp-partners.roitiv.com/wp-content/uploads/2023/10/1000x1000px-15.png"
          />
        </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
        <Box
            onClick={() => {
              navigate("/jehona/360");
            }}
            sx={{
              ...navbarStyle.threedBox,
              fontSize: '13px',
            }}
          >
            3D
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '3px',
              alignItems: 'center',
              fontSize: '11px',
              borderRadius: 7,
              p: '5px',
              backgroundColor: 'white',
              color: '#527540',
              border: '1px solid #527540',
              userSelect: 'none',
              cursor: 'pointer'
            }}
          >
            <Search sx={{ color: "#527540" }} fontSize="small" />
            {"Zgjedh Banesen".toUpperCase()}
          </Box>
        </Box>
      </Box>
    </Toolbar>
  );
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        DP Partners
      </Typography>
      <Divider sx={{backgroundColor: 'white'}}/>
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  return (
    <>
    
    <AppBar position="fixed" sx={{backgroundColor: 'rgba(255, 255, 255, 0.45)'}}>
          {appBarLabel}
        </AppBar>
        <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: colors.navBarBackgroundColor, color: 'white' },
          }}
        >
          {drawer}
        </Drawer>
      </nav></>
    
  );
}