import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos, ArrowUpward, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getFloorFilter, handleFloorFilter } from '../../features/filter/FilterSlice';

const Filter = () => {
  const floorNumber = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
  const [inView, setInView] = useState([1, 5]);
  const floorFilter = useSelector(getFloorFilter);
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: {
          md: "center",
        },
        p: 1,
        alignItems: 'center',
        px: 2,
        overflow: "hidden",
        gap: {
          md: 10,
        },
      }}
    >
      <Box>
        
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
          flexDirection: {
            md: 'column',
            xs: 'row'
        },
        }}>
          <IconButton onClick={() => {
            if(floorFilter <= inView[0] && inView[1] < 10) {
              dispatch(handleFloorFilter(floorFilter + 1))
            }
           setInView(prevState => {
            const newInView = [...prevState];
            if((newInView[1] < 10)){
              newInView[1] = newInView[1] + 1;
              newInView[0] = newInView[0] + 1;
            }
            return newInView;
          });
          }} sx={{ mr: {
            xs: 2,
            md: 0
          }, color: {
            md: 'black',
            xs: 'white'
          },
          }}>
          <ArrowBackIos sx={{
            display: {
              xs: 'block',
              md: 'none'
            }
          }} fontSize='large'/>
          <KeyboardArrowUp sx={{
            display: {
              xs: 'none',
              md: 'block'
            }
          }} fontSize='large'/>
          </IconButton>
        {
          floorNumber.map((item) => {
            let nr = 30;
            if(item === inView[0] || item === inView[1]){
              nr = 25;
            }
            if(item === inView[0] + 1 || item === inView[1] - 1){
              nr = 30;
            }
            if(item === inView[0] + 2){
              nr = 40
            }
            return <Box key={item}
            onClick={() => {
              dispatch(handleFloorFilter(item))
            }}
            sx={{
              width: (item >= inView[0]) && (item <= inView[1])  ? 30 : 'unset',
              height: (item >= inView[0]) && (item <= inView[1]) ? 30 : 'unset',
              opacity: (item >= inView[0]) && (item <= inView[1]) ? 1 : 0,
              p: (item >= inView[0]) && (item <= inView[1]) ? 1 : 0 ,
              my: (item >= inView[0]) && (item <= inView[1]) ? 1 : 0,
              ":hover": {
                backgroundColor: {
                  md: 'rgba(0, 0, 0, 0.15)',
                }
              },
              color: {
                xs: floorFilter === item ? 'white' : 'black',
                md: item === floorFilter ? 'white' : 'black',
              },
              backgroundColor: {
                xs: item === floorFilter ? '#527540' : 'white',
                md: item === floorFilter ? 'black' : 'white',
              },
              display: 'flex',
              transition: 'all 0.5s ease-in-out',
              mx: {
                xs: item <= inView[1] && '1px',
                md: 0
              },
              border: {
                md: '1px solid black',
              },
              justifyContent: 'center',
              boxShadow: {
                md: 'rgba(0, 0, 0, 0.35) 0px 1px 7px',
              } ,
              alignItems: 'center',
              borderRadius: 50,
              cursor: 'pointer',
            }}>
              {(item >= inView[0]) && (item <= inView[1]) ? <Typography>{item}</Typography> : null}
            </Box>
            // if((item >= inView[0]) && (item <= inView[1])) {
            //   return <Box key={item} sx={{
            //     width: 30,
            //     height: 30,
            //     p: 1,
            //     color: {
            //       xs: 'black',
            //       md: 'black'
            //     },
            //     backgroundColor: {
            //       xs: 'white',
            //       md: 'white'
            //     },
            //     display: 'flex',
            //     border: {
            //       md: '1px solid black',
            //     },
            //     justifyContent: 'center',
            //     boxShadow: {
            //       md: 'rgba(0, 0, 0, 0.35) 0px 1px 7px',
            //     } ,
            //     alignItems: 'center',
            //     borderRadius: 2,
            //     cursor: 'pointer',
            //     ":hover": {
            //       backgroundColor: {
            //         md: 'rgba(0, 0, 0, 0.15)',
            //       }
            //     }
            //   }}>
            //     <Typography>{item}</Typography>
            //   </Box>
            // }
            // else {

            // }
          })
        }
          <IconButton onClick={() => {
            if(floorFilter >= inView[1] && inView[0] > 1){
              dispatch(handleFloorFilter(floorFilter - 1))
            }
           setInView(prevState => {
            const newInView = [...prevState];
            if((newInView[0] > 1) && (newInView[1] > 5)){
              newInView[1] = newInView[1] - 1;
              newInView[0] = newInView[0] - 1;
            }
            return newInView;
          });
          }} sx={{ ml: {
            xs: 2,
            md: 0
          }, 
          color: {
            md: 'black',
            xs: 'white'
          },
          }}>
          <ArrowForwardIos sx={{
            display: {
              xs: 'block',
              md: 'none'
            }
          }} fontSize='large'/>
          <KeyboardArrowDown sx={{
            display: {
              xs: 'none',
              md: 'block'
            }
          }} fontSize='large'/>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Filter;
