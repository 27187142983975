import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../../navbar/Navbar'
import SvgHolder from './components/SvgHolder'
import ApartmentDetailsModal from '../../modal/ApartmentDetailsModal'
import { getFilterMobileModalState, handleMobileModal, handleResetFilter } from '../../../features/filter/FilterSlice'
import MobileFilter from '../../filter/jehona/MobileFilter'
import { colors, customFontFamily } from '../../../utils/theme'
import { getApartmentDetailModalState } from '../../../features/apartment/ApartmentDetailSlice'
import { useParams } from 'react-router-dom'

const SmallDevice = () => {
  const detailsModalState = useSelector(getApartmentDetailModalState);
  const mobileFilterModal = useSelector(getFilterMobileModalState);
  const theme = useTheme();
  const isSmallDev = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  return (
    <Box bgcolor={'grey'} sx={{overflowY: 'hidden'}}>
        <Navbar />
        <Box mt={13} bgcolor={'grey'} width={'300%'}>
            <SvgHolder />
        </Box>
        {isSmallDev && <Box sx={{
          display: {
            xs: 'flex',
            md: 'none'
          },
          position: 'fixed',
          bottom: 1,
          left: 0,
          justifyContent: 'space-between',
          width: '100%',

        }}>
          <Button variant='outlined' fullWidth sx={{
            m: 1,
            color: 'black',
            borderRadius: '50px',
            backgroundColor: ' white',
            borderColor: colors.lightGreen,
            ':hover': {
              borderColor: colors.lightGreen,
              backgroundColor: ' white',
            }
          }} onClick={() => {
            dispatch(handleResetFilter())
            
          }}>
            Reseto
          </Button>
          <Button onClick={() => {
            dispatch(handleMobileModal(true))
          }} fullWidth sx={{
            m: 1,
            borderRadius: '50px',
            backgroundColor: colors.lightGreen,
            ':hover': {
              backgroundColor: colors.lightGreen,
            },
            color: 'white'
          }} >
            Filtro
          </Button>
        </Box>}
        
        {detailsModalState && <ApartmentDetailsModal />}
        {mobileFilterModal && <MobileFilter apartment />}
    </Box>
  )
}
export default SmallDevice