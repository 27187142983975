import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { cardStyle } from '../../utils/theme'
import './style.css'
import { useNavigate } from 'react-router-dom'

const Card = ( { imageUrl, description, locationUrl, onClick } ) => {
    const navigate = useNavigate()
  return (
    <Box onClick={
        () => {
            if(locationUrl)
                {
                    navigate(`/${description.toLowerCase()}`)
                }
            else 
                {
                    if(onClick){
                        onClick()
                    }
                }
        }
    } sx={{ ...cardStyle.wrapper, cursor: 'pointer'}}>
        <Box sx={{
            ...cardStyle.imageContainer
        }}>
            <img className='cardImage' width={'100%'} src={imageUrl} />
            <Box sx={{...cardStyle.gradient, pointerEvents: 'none'}}></Box>
            <Typography sx={{...cardStyle.text }}>{description}</Typography>
        </Box>
        <Box sx={{
            p: 1
        }}>
            <Button variant='outlined'>Me shume</Button>
        </Box>
    </Box>
  )
}

export default Card