import { Box, Typography } from '@mui/material'
import React from 'react'
import IconWrapper from '../../common/IconWrapper'
import { bedIconSvg, buildingIconSvg } from '../../../utils/consts'
import { AspectRatio, FilterAlt, KeyboardArrowDown, Restore } from '@mui/icons-material'
import { useState } from 'react'
import FilterCard from '../components/FilterCard'
import CustomSlider from '../components/CustomSlider'
import CustomRoomFilter from '../components/projectFilter/CustomRoomFilter'
import { useDispatch, useSelector } from 'react-redux'
import { getFilterData, handleResetFilter } from '../../../features/filter/FilterSlice'


const FilterBox = () => {
  const [popover, setPopover] = useState({
      anchorEl: null,
      child: null,
  })
  const handleFilter = (e, b) => {
      setPopover({
          anchorEl: e.target,
          child: b,
      })
  }
  const dispatch = useDispatch()
  const filterData = useSelector(getFilterData)
  const squareSliderFilter = <CustomSlider data={'square'}/>; 
  const floorSliderFilter = <CustomSlider data={'floor'}/>; 
  const roomsFilter = <CustomRoomFilter />;
   ; 
  return (
    <Box
      sx={{
        width: "100%",
        height: 70,
        backgroundColor: '#ffffff94',
        display: "flex",
        flexWrap: "wrap",
        border: "2px solid #D8D8D8",
        borderRadius: "14px",
        userSelect: 'none',
        mt: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: {
            xs: 12,
            md: 1,
            lg: 3,
          }
        }}
      >
        <Box
        onClick={(e) => handleFilter(e, roomsFilter)}
          sx={{
            width: "95%",
            border: "1px solid #87AA86",
            borderRadius: '50px',
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 1,
            cursor: 'pointer',
            backgroundColor: 'rgba(255,255,255,0.5)',
          }}
        >
          <Box display={"flex"} gap={1} alignItems={'center'} ml={1}>
            <IconWrapper svg={bedIconSvg} />
            <Typography fontFamily={'Inter'} fontSize={'16px'}>Dhoma</Typography>
          </Box>
          <Typography fontSize={'14px'}>{filterData.rooms.map((item, index) => {
            if(!item.includes('all')){
              if(index >= filterData.rooms.length - 1) {
                return (
                  item
                )
              }
              else {
                return (
                  item + ', '
                )
              }
            }
          })}</Typography>
          <KeyboardArrowDown />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: {
            xs: 12,
            md: 1,
            lg: 3,
          }
        }}
      >
        <Box
          onClick={(e) => handleFilter(e, floorSliderFilter)}
          sx={{
            width: "95%",
            border: "1px solid #87AA86",
            borderRadius: '50px',
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 1,
            cursor: 'pointer',
            backgroundColor: 'rgba(255,255,255,0.5)',
          }}
        >
          <Box display={"flex"} gap={1} alignItems={'center'} ml={1}>
            <IconWrapper svg={buildingIconSvg}/>
            <Typography fontFamily={'Inter'} fontSize={'16px'}>Kati</Typography>
          </Box>
          <Typography fontSize={'14px'}>{filterData.floor.startVal} {' - '} {filterData.floor.endVal}</Typography>
          <KeyboardArrowDown />
        </Box>
      </Box>
      <Box
       
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: {
            xs: 12,
            sm: 5,
            md: 3,
            lg: 3,
          }
        }}
      >
        <Box
          onClick={(e) => handleFilter(e, squareSliderFilter)}
          sx={{
            width: "95%",
            border: "1px solid #87AA86",
            borderRadius: '50px',
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 1,
            cursor: 'pointer',
            backgroundColor: 'rgba(255,255,255,0.5)',
          }}
        >
          <Box display={"flex"} gap={1} alignItems={'center'} ml={1}>
            <AspectRatio />
            <Typography fontFamily={'Inter'} fontSize={'16px'}>Sipërfaqja</Typography>
          </Box>
          <Typography fontSize={'14px'}>{filterData.square.startVal}m <sup>2</sup> {' - '} {filterData.square.endVal}m <sup>2</sup></Typography>
          <KeyboardArrowDown />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: {
            xs: 12,
            md: 2,
            xl: 3,
          }
        }}
      >
        <Box
          sx={{
            width: "95%",
            border: "2px solid #87AA86",
            borderRadius: '50px',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: 'white',
            py: 1,
            cursor: 'pointer'
          }}
        >
          <Box onClick={() => {
            dispatch(handleResetFilter())
          }} display={"flex"} gap={1} alignItems={'center'}>
            <Restore />
            <Typography fontFamily={'Inter'} fontSize={'16px'}>Reseto</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: {
            xs: 12,
            md: 2,
            xl: 3,
          }
        }}
      >
        <Box
          sx={{
            width: "95%",
            border: "1px solid white",
            borderRadius: '50px',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: '#74A284',
            py: 1,
            cursor: 'pointer'
          }}
        >
          <Box onClick={() => {
            dispatch(handleResetFilter())
          }} display={"flex"} gap={1} alignItems={'center'}>
            <FilterAlt />
            <Typography fontFamily={'Inter'} fontSize={'16px'}>Filtro</Typography>
          </Box>
        </Box>
      </Box>
      <FilterCard controls={{popover, setPopover}} children={popover.child}/>
    </Box>
  );
}

export default FilterBox