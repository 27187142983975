import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';

const PageLoading = () => {
  useEffect(() => {
    const disablePointerEvents = () => {
      document.body.style.pointerEvents = 'none';
      document.body.style.userSelect = 'none'
    };

    const enablePointerEvents = () => {
      document.body.style.pointerEvents = 'auto';
      document.body.style.userSelect = 'auto'
    };

    disablePointerEvents();

    return enablePointerEvents;
  }, []);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(99, 99, 99, 0.7)',
        backdropFilter: 'blur(6px)',
        zIndex: 9999,
        userSelect: 'none',
        pointerEvents: 'none',
      }}
    >
      <CircularProgress sx={{color: 'white', opacity: 1}} size={120} />
    </Box>
  );
}

export default PageLoading