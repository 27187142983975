import { createSlice } from "@reduxjs/toolkit"

const initialState = {   
    filter: {
        square: {
            startVal: 40,
            endVal: 300
        },
        rooms:['all'],
        floor: {
            startVal: 1,
            endVal: 10,
        },
        showSmallDev: false,
    },

    filterPlan: {
        square: {
            startVal: 40,
            endVal: 300
        },
        rooms:['all'],
        location: ['all'],
        status: [],
        showSmallDev: false,
    },

    mobileModal: false,
    mobileFilterPlanModal: false,
    filterState: false,
    modalState: {
        open: false,
        anchorEl: null,
    },
    filteredLength: 0,
    floorFilter: null,
}

const FilterSlice = createSlice({
    name: 'FilterSlice',
    initialState,
    reducers: {
        setFilterRoom(state, action) {
            const exists = state.filter.rooms.includes(action.payload)
            if(exists) {
                
                state.filter.rooms = state.filter.rooms.filter((item) => !item.includes(action.payload))
                
            }
            else {
                state.filter.rooms.push(action.payload)
                state.filter.rooms = state.filter.rooms.filter((item) => !item.includes('all'))
            }
            
        },
        setPlanFilterLocation(state, action) {
            const exists = state.filterPlan.location.includes(action.payload)
            if(exists) {
                
                state.filterPlan.location = state.filterPlan.location.filter((item) => !item.includes(action.payload))
                
            }
            else {
                state.filterPlan.location.push(action.payload)
                state.filterPlan.location = state.filterPlan.location.filter((item) => !item.includes('all'))
            }
            
        },
        setPlanFilterSquare(state, action) {
            state.filterPlan.square.endVal = action.payload[1]
            state.filterPlan.square.startVal = action.payload[0]
        },
        setPlanFilterRoom(state, action) {
            const exists = state.filterPlan.rooms.includes(action.payload)
            if(exists) {
                
                state.filterPlan.rooms = state.filterPlan.rooms.filter((item) => !item.includes(action.payload))
                
            }
            else {
                state.filterPlan.rooms.push(action.payload)
                state.filterPlan.rooms = state.filterPlan.rooms.filter((item) => !item.includes('all'))
            }
            
        },
        setFilterSquare(state, action) {
            state.filter.square.endVal = action.payload[1]
            state.filter.square.startVal = action.payload[0]
        },
        setFilterFloor(state, action) {
            state.filter.floor.startVal = action.payload[0]
            state.filter.floor.endVal = action.payload[1]
        },
        handleResetFilter(state) {
            state.filter.rooms = ['all'];
            state.filter.floor.endVal = 10.00;
            state.filter.floor.startVal = 1;
            state.filter.square.endVal = 300.00;
            state.filter.square.startVal = 40.00;
        },
        handleResetFilterPlan(state) {
            state.filterPlan.rooms = ['all'];
            state.filterPlan.location = ['all'];
            state.filterPlan.square.endVal = 300.00;
            state.filterPlan.square.startVal = 40.00;
        },
        handleSquareChange(state, action) {
            state.square.startVal = action.payload[0];
            state.square.endVal = action.payload[1];
        },
        handleFilterModalState(state, action) {
            state.modalState.open = action.payload.isOpen;
        },
        handleMobileModal(state, action) {
            state.mobileModal = action.payload;
        },
        handleFilteredLength(state, action) {
            state.filteredLength = action.payload
        },
        handleFloorFilter(state, action) {
            state.floorFilter = action.payload;
        },
        handleFilterState(state, action) {
            state.filterState = action.payload;
        },
        handleMobilePlanFilterState(state, action) {
            state.mobileFilterPlanModal = action.payload;
        },
    }
})
export const {
    handleFilter,
    handleSquareChange,
    handleFilterModalState,
    handleMobileModal,
    handleFilteredLength,
    handleResetFilter,
    handleFloorFilter,
    setFilterRoom,
    setFilterFloor,
    setFilterSquare,
    handleFilterState,
    setPlanFilterLocation,
    setPlanFilterRoom,
    setPlanFilterSquare,
    handleMobilePlanFilterState,
    handleResetFilterPlan
} = FilterSlice.actions;
export const getFilterMobileModalState = (state) => state.FilterSlice.mobileModal;
export const getFilterModalState = (state) => state.FilterSlice.modalState;
export const getFilterLength = (state) => state.FilterSlice.filteredLength;
export const getFilterData = (state) => state.FilterSlice.filter;
export const getFloorFilter = (state) => state.FilterSlice.floorFilter;
export const getFilterState = (state) => state.FilterSlice.filterState;
export const getPlanFilterData = (state) => state.FilterSlice.filterPlan;
export const getMobilePlanFilterState = (state) => state.FilterSlice.mobileFilterPlanModal;
export default FilterSlice.reducer;